import Authorized from '@/utils/Authorized';
import React from 'react';
import Redirect from 'umi/redirect';
import { connect } from 'dva';
import pathToRegexp from 'path-to-regexp';
import { controlPage } from '@/services/Controllers/Login';
import { getToken } from '@/services/Controllers/Token';

const getRouteAuthority = (path, routeData) => {
    let authorities;
    routeData.forEach(route => {
        // match prefix
        if (pathToRegexp(`${route.path}(.*)`).test(path)) {
            // exact match
            if (route.path === path) {
                authorities = route.authority || authorities;
            } // get children authority recursively
            if (route.routes) {
                authorities = getRouteAuthority(path, route.routes) || authorities;
            }
        }
    });
    controlPage(path);
    return authorities;
};

const AuthComponent = ({
    children,
    route = {
        routes: [],
    },
    location = {
        pathname: '',
    },
    user,
}) => {
    const { currentUser } = user;
    const { routes = [] } = route;
    const isLogin = currentUser && currentUser.name;
    const hasToken = getToken();

    return (
        <Authorized
            authority={getRouteAuthority(location.pathname, routes) || ''}
            noMatch={
                isLogin || hasToken ? (
                    <Redirect to={PATH.FORBIDDEN} />
                ) : (
                    <Redirect to={PATH.LOGIN} />
                )
            }
        >
            {children}
        </Authorized>
    );
};

export default connect(({ user }) => ({
    user,
}))(AuthComponent);
