import SessionStorage from './SessionStorage';

export default class SessionStorageAuthority extends SessionStorage {
    static getAuthority = () =>
        super.getSessionStorageData(SESSION_STORAGE_KEYS.ANTD_PRO_AUTHORITY);

    static setAuthority = value => {
        super.setSessionStorageData(SESSION_STORAGE_KEYS.ANTD_PRO_AUTHORITY, value);
    };
}
