import { getToken } from '@/services/Controllers/Token';
import { PROCESSOS_OPTIONS } from '@/components/Form/CadastroLojista/CadastroProcessos/cadastro-processo.constants';
import { axiosRequest, axiosRequestDashboard } from './get';

import {
    urlBaseProposta,
    baseRestCDC_API,
    urlConsultaLimite,
    urlConsultaPropostas,
    urlGetAllRetailer,
    urlConsultaClientes,
    urlConsultaLimiteDashboard,
    urlConsultaPropostasValor,
} from '../../utils/Requests/url';

import { request } from './request';

export const dash_board_propostas_tipo = async (params, callbackSuccess, callbackError) => {
    const { idcdcstatus, firstday, lastday, idcdclojista, idcdclojistafiliais } = params;
    const options = {
        method: 'GET',
        url: `${baseRestCDC_API}/dashboard/propostas/historico/status/${idcdcstatus}/dataproposta/inicio/${firstday}/final/${lastday}`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        params: {
            idcdclojista,
            idcdclojistafiliais,
        },
    };
    await axiosRequestDashboard(options, callbackSuccess, callbackError);
};

export const dash_board_clientes_mes = async (data, callbackSuccess, callbackError) => {
    const { firstday, lastday, idcdclojista, idcdclojistafiliais } = data;
    const options = {
        method: 'GET',
        url: `${baseRestCDC_API}/dashboard/clientes/historicos/propostas/periodos/inicio/${firstday}/final/${lastday}`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        params: {
            idcdclojista,
            idcdclojistafiliais,
        },
    };
    await axiosRequestDashboard(options, callbackSuccess, callbackError);
};

export const ConsultaLimitecdc = async (idcdclojista, callbackSuccess, callbackError) => {
    const options = {
        method: 'GET',
        url: urlConsultaLimite(idcdclojista),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    await axiosRequest(options, callbackSuccess, callbackError);
};

export const consulta_lojista = async (callbackSuccess, callbackError) => {
    const options = {
        method: 'GET',
        url: urlGetAllRetailer,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    await axiosRequest(options, callbackSuccess, callbackError);
};

export const ConsultaPropostas = async (firstday, lastday, callbackSuccess, callbackError) => {
    const options = {
        method: 'GET',
        url: urlConsultaPropostas(firstday, lastday),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    await axiosRequest(options, callbackSuccess, callbackError);
};

export const ConsultaPropostasData = async data => {
    const { firstday, lastday, idcdclojista, idcdclojistafiliais } = data;
    const options = {
        method: 'GET',
        url: urlConsultaPropostas(firstday, lastday),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        params: {
            idcdclojista,
            idcdclojistafiliais,
        },
    };
    return request(options);
};

export const getProposal = async data => {
    const processos = PROCESSOS_OPTIONS.map(
        processo => processo.payload.processo_titulos_a_receber,
    );
    const options = {
        method: 'GET',
        url: urlBaseProposta,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        params: {
            ...data,
            processos: JSON.stringify(processos),
        },
    };
    return request(options);
};

export const ConsultaPropostasCardData = async data => {
    const { firstday, lastday, idcdclojista, idcdclojistafiliais } = data;
    const options = {
        method: 'GET',
        url: urlConsultaPropostas(firstday, lastday),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        params: {
            idcdclojista,
            idcdclojistafiliais,
        },
    };
    return request(options);
};

export const ConsultaPropostasValor = async data => {
    const { firstday, lastday, idcdclojista, idcdclojistafiliais } = data;
    const options = {
        method: 'GET',
        url: urlConsultaPropostasValor(firstday, lastday),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        params: {
            idcdclojista,
            idcdclojistafiliais,
        },
    };
    return request(options);
};

export const ConsultaPropostasPieData = async data => {
    const { firstday, lastday, idcdclojista, idcdclojistafiliais } = data;
    const options = {
        method: 'GET',
        url: urlConsultaPropostas(firstday, lastday),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        params: {
            idcdclojista,
            idcdclojistafiliais,
        },
    };
    return request(options);
};

export const dash_board_clientes_diaData = async data => {
    const { firstday, lastday, idcdclojista, idcdclojistafiliais } = data;
    const options = {
        method: 'GET',
        url: urlConsultaClientes(firstday, lastday),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        params: {
            idcdclojista,
            idcdclojistafiliais,
        },
    };
    return request(options);
};

export const dash_board_lojista_limite = async () => {
    const options = {
        method: 'GET',
        url: urlConsultaLimiteDashboard,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    return request(options);
};
