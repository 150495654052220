export default {
    namespace: 'cadastroCliente_dadosPessoais_filiacao',
    state: {
    },
    reducers: {
        handlerOnChange(state, { payload: dados }) {
            return {
                 ...state,
                 ...dados,
            };
        },
    },
};
