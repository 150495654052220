import { AxiosResponse } from 'axios';
import fenixInstance from '../FenixAPI';

interface IResponseEPC {
    epcApproved: boolean;
}

interface IRequestEPC {
    cnpj_cpf: string;
    idFilialUsuario: string;
    valorPedido: number;
    datacriacao: string;
    occupationType: string;
}

const makeEPCEndpoint = (data: IRequestEPC) => {
    const { cnpj_cpf, idFilialUsuario, valorPedido, datacriacao, occupationType } = data;
    return `/epc/${cnpj_cpf}/${idFilialUsuario}/${valorPedido}/${datacriacao}?occupationType=${occupationType}&ignoreSimplified=true`;
};

export const requestEPC = (data: IRequestEPC): Promise<AxiosResponse<IResponseEPC>> =>
    fenixInstance.get(makeEPCEndpoint(data));
