import { getAuxiliaryData } from '@/services/Request/auxiliaries';
import { LocalStorageAuxiliaries } from '@/services/LocalStorage';
import { reloadAuthorized } from '@/utils/Authorized';
import { logout } from '@/services/Controllers/Login';
import router from 'umi/router';
import { getRoutes, getInitialRoutePath } from '@/pages/Route/services';
import { Notification } from '@/components/Modal/Notification';
import { decodeTokenAPI } from '@/services/Controllers/Token';

export default {
    namespace: 'model_system',
    state: {
        auxiliaryData: {},
        showPassword: {},
    },
    reducers: {
        setAuxiliaryData(state, { payload: auxiliaryData }) {
            return {
                ...state,
                auxiliaryData,
            };
        },
        setShowPassword(state, { payload: showPassword }) {
            return {
                ...state,
                showPassword,
            };
        },
    },
    effects: {
        *systemInit(_, { call, put }) {
            yield put({ type: 'spin/setSpinning', payload: true });

            try {
                yield put({ type: 'initShowChangePassword' });
                const response = yield call(getAuxiliaryData);
                yield put({ type: 'setAuxiliaryData', payload: response.data });
                yield call(LocalStorageAuxiliaries.setData, response.data);

                if (!response.data || response.data.length === 0) {
                    throw {
                        code: EXCEPTION_CODE.AUX_CODE,
                        name: PROJECT_MESSAGE.ERROR_TITLE_AUX,
                        message: PROJECT_MESSAGE.ERROR_MSG_CONTACT_SUPPORT,
                    };
                }

                reloadAuthorized();
                if (getRoutes().length) {
                    router.push(getInitialRoutePath());
                } else {
                    throw {
                        code: EXCEPTION_CODE.AUX_CODE,
                        name: PROJECT_MESSAGE.ERROR_TITLE_ACCESS,
                        message: PROJECT_MESSAGE.ERROR_MSG_FIND_PERMISSIONS,
                    };
                }
            } catch (error) {
                if (error.code == EXCEPTION_CODE.AUX_CODE) {
                    Notification(PROJECT_MESSAGE.ERROR_TYPE_MESSAGE, error.name, error.message);
                } else {
                    Notification(
                        PROJECT_MESSAGE.ERROR_TYPE_MESSAGE,
                        PROJECT_MESSAGE.ERROR_TITLE_AUX,
                        PROJECT_MESSAGE.ERROR_MSG_CONTACT_SUPPORT,
                    );
                }
                logout();
            }

            yield put({ type: 'spin/setSpinning', payload: false });
        },
        *initShowChangePassword(_, { put }) {
            yield put({
                type: 'setShowPassword',
                payload: {
                    alterarSenha: decodeTokenAPI().alterarsenha,
                    expirarSenha: decodeTokenAPI().dataexpiracao,
                },
            });
        },
    },
};
