import { parse, stringify } from 'qs';
import { routerRedux } from 'dva/router';

export function getPageQuery() {
    return parse(window.location.href.split('?')[1]);
}
const Model = {
    namespace: 'login',
    state: {
        status: undefined,
    },
    effects: {
        *logout(_, { put }) {
            const { redirect } = getPageQuery(); // redirect
            if (window.location.pathname !== '/login' && !redirect) {
                yield put(
                    routerRedux.replace({
                        pathname: '/login',
                        search: stringify({
                            redirect: window.location.href,
                        }),
                    }),
                );
            }
        },
    },
    reducers: {
        changeLoginStatus(state, { payload }) {
            return { ...state, status: payload.status, type: payload.type };
        },
    },
};
export default Model;
