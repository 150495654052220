import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/usr/src/app/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/login',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__LoginLayout" */ '../../layouts/LoginLayout'),
          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/LoginLayout').default,
    routes: [
      {
        path: '/login',
        name: 'Login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Login" */ '../Login'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../Login').default,
        exact: true,
      },
    ],
  },
  {
    path: '/recuperarsenha',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__LoginLayout" */ '../../layouts/LoginLayout'),
          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/LoginLayout').default,
    routes: [
      {
        path: '/recuperarsenha',
        name: 'Recuperar senha',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__ForgotPassword" */ '../ForgotPassword'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../ForgotPassword').default,
        exact: true,
      },
      {
        path: '/recuperarsenha/:hash',
        name: 'Redefinir senha',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__ResetPassword" */ '../ResetPassword'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../ResetPassword').default,
        exact: true,
      },
    ],
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BasicLayout'),
          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BasicLayout').default,
    Routes: [require('../Authorized').default],
    routes: [
      {
        path: '/proposta',
        name: 'Proposta',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__Proposta__models__arkivus.js' */ '/usr/src/app/src/pages/Proposta/models/arkivus.js').then(
                  m => {
                    return { namespace: 'arkivus', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__contractClauses.js' */ '/usr/src/app/src/pages/Proposta/models/contractClauses.js').then(
                  m => {
                    return { namespace: 'contractClauses', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__creditLimit.js' */ '/usr/src/app/src/pages/Proposta/models/creditLimit.js').then(
                  m => {
                    return { namespace: 'creditLimit', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__creditLimitForm.js' */ '/usr/src/app/src/pages/Proposta/models/creditLimitForm.js').then(
                  m => {
                    return { namespace: 'creditLimitForm', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__customerEpc.js' */ '/usr/src/app/src/pages/Proposta/models/customerEpc.js').then(
                  m => {
                    return { namespace: 'customerEpc', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__customerLimit.js' */ '/usr/src/app/src/pages/Proposta/models/customerLimit.js').then(
                  m => {
                    return { namespace: 'customerLimit', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__fetchProposalsForm.js' */ '/usr/src/app/src/pages/Proposta/models/fetchProposalsForm.js').then(
                  m => {
                    return { namespace: 'fetchProposalsForm', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__person.js' */ '/usr/src/app/src/pages/Proposta/models/person.js').then(
                  m => {
                    return { namespace: 'person', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__proposal.js' */ '/usr/src/app/src/pages/Proposta/models/proposal.js').then(
                  m => {
                    return { namespace: 'proposal', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__proposalContract.js' */ '/usr/src/app/src/pages/Proposta/models/proposalContract.js').then(
                  m => {
                    return { namespace: 'proposalContract', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__proposalContractSelectStep.js' */ '/usr/src/app/src/pages/Proposta/models/proposalContractSelectStep.js').then(
                  m => {
                    return {
                      namespace: 'proposalContractSelectStep',
                      ...m.default,
                    };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__proposalFinalization.js' */ '/usr/src/app/src/pages/Proposta/models/proposalFinalization.js').then(
                  m => {
                    return { namespace: 'proposalFinalization', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__proposalUpdateStep.js' */ '/usr/src/app/src/pages/Proposta/models/proposalUpdateStep.js').then(
                  m => {
                    return { namespace: 'proposalUpdateStep', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__shopkeeper.js' */ '/usr/src/app/src/pages/Proposta/models/shopkeeper.js').then(
                  m => {
                    return { namespace: 'shopkeeper', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__shopkeeperLimit.js' */ '/usr/src/app/src/pages/Proposta/models/shopkeeperLimit.js').then(
                  m => {
                    return { namespace: 'shopkeeperLimit', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__simulation.js' */ '/usr/src/app/src/pages/Proposta/models/simulation.js').then(
                  m => {
                    return { namespace: 'simulation', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__simulationForm.js' */ '/usr/src/app/src/pages/Proposta/models/simulationForm.js').then(
                  m => {
                    return { namespace: 'simulationForm', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__simulationModal.js' */ '/usr/src/app/src/pages/Proposta/models/simulationModal.js').then(
                  m => {
                    return { namespace: 'simulationModal', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__updateProposalModal.js' */ '/usr/src/app/src/pages/Proposta/models/updateProposalModal.js').then(
                  m => {
                    return { namespace: 'updateProposalModal', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__Proposta" */ '../Proposta'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../Proposta').default,
        exact: true,
      },
      {
        path: '/cadastrar/rota',
        authority: ['/cadastrar/rota'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__Route__model.js' */ '/usr/src/app/src/pages/Route/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__Route" */ '../Route'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../Route').default,
        exact: true,
      },
      {
        path: '/inicio',
        authority: ['/inicio'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Welcome" */ '../Welcome'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../Welcome').default,
        exact: true,
      },
      {
        path: '/dashboard',
        authority: ['/dashboard'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Dashboard" */ '../Dashboard'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../Dashboard').default,
        exact: true,
      },
      {
        path: '/proposta',
        authority: ['/proposta'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__Proposta__models__arkivus.js' */ '/usr/src/app/src/pages/Proposta/models/arkivus.js').then(
                  m => {
                    return { namespace: 'arkivus', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__contractClauses.js' */ '/usr/src/app/src/pages/Proposta/models/contractClauses.js').then(
                  m => {
                    return { namespace: 'contractClauses', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__creditLimit.js' */ '/usr/src/app/src/pages/Proposta/models/creditLimit.js').then(
                  m => {
                    return { namespace: 'creditLimit', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__creditLimitForm.js' */ '/usr/src/app/src/pages/Proposta/models/creditLimitForm.js').then(
                  m => {
                    return { namespace: 'creditLimitForm', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__customerEpc.js' */ '/usr/src/app/src/pages/Proposta/models/customerEpc.js').then(
                  m => {
                    return { namespace: 'customerEpc', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__customerLimit.js' */ '/usr/src/app/src/pages/Proposta/models/customerLimit.js').then(
                  m => {
                    return { namespace: 'customerLimit', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__fetchProposalsForm.js' */ '/usr/src/app/src/pages/Proposta/models/fetchProposalsForm.js').then(
                  m => {
                    return { namespace: 'fetchProposalsForm', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__person.js' */ '/usr/src/app/src/pages/Proposta/models/person.js').then(
                  m => {
                    return { namespace: 'person', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__proposal.js' */ '/usr/src/app/src/pages/Proposta/models/proposal.js').then(
                  m => {
                    return { namespace: 'proposal', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__proposalContract.js' */ '/usr/src/app/src/pages/Proposta/models/proposalContract.js').then(
                  m => {
                    return { namespace: 'proposalContract', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__proposalContractSelectStep.js' */ '/usr/src/app/src/pages/Proposta/models/proposalContractSelectStep.js').then(
                  m => {
                    return {
                      namespace: 'proposalContractSelectStep',
                      ...m.default,
                    };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__proposalFinalization.js' */ '/usr/src/app/src/pages/Proposta/models/proposalFinalization.js').then(
                  m => {
                    return { namespace: 'proposalFinalization', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__proposalUpdateStep.js' */ '/usr/src/app/src/pages/Proposta/models/proposalUpdateStep.js').then(
                  m => {
                    return { namespace: 'proposalUpdateStep', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__shopkeeper.js' */ '/usr/src/app/src/pages/Proposta/models/shopkeeper.js').then(
                  m => {
                    return { namespace: 'shopkeeper', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__shopkeeperLimit.js' */ '/usr/src/app/src/pages/Proposta/models/shopkeeperLimit.js').then(
                  m => {
                    return { namespace: 'shopkeeperLimit', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__simulation.js' */ '/usr/src/app/src/pages/Proposta/models/simulation.js').then(
                  m => {
                    return { namespace: 'simulation', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__simulationForm.js' */ '/usr/src/app/src/pages/Proposta/models/simulationForm.js').then(
                  m => {
                    return { namespace: 'simulationForm', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__simulationModal.js' */ '/usr/src/app/src/pages/Proposta/models/simulationModal.js').then(
                  m => {
                    return { namespace: 'simulationModal', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Proposta__models__updateProposalModal.js' */ '/usr/src/app/src/pages/Proposta/models/updateProposalModal.js').then(
                  m => {
                    return { namespace: 'updateProposalModal', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__Proposta" */ '../Proposta'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../Proposta').default,
        exact: true,
      },
      {
        path: '/contrato',
        authority: ['/contrato'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__ContratoFilial" */ '../ContratoFilial'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../ContratoFilial').default,
        exact: true,
      },
      {
        path: '/cadastrar/lojista',
        authority: ['/cadastrar/lojista'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__CadastrarLojista__models__modalCep.js' */ '/usr/src/app/src/pages/CadastrarLojista/models/modalCep.js').then(
                  m => {
                    return { namespace: 'modalCep', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__CadastrarLojista__models__registerFiador.js' */ '/usr/src/app/src/pages/CadastrarLojista/models/registerFiador.js').then(
                  m => {
                    return { namespace: 'registerFiador', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__CadastrarLojista__models__registerFiadorForm.js' */ '/usr/src/app/src/pages/CadastrarLojista/models/registerFiadorForm.js').then(
                  m => {
                    return { namespace: 'registerFiadorForm', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__CadastrarLojista__models__registerFiadorTab.js' */ '/usr/src/app/src/pages/CadastrarLojista/models/registerFiadorTab.js').then(
                  m => {
                    return { namespace: 'registerFiadorTab', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__CadastrarLojista__models__shopkeeperAddressSearch.js' */ '/usr/src/app/src/pages/CadastrarLojista/models/shopkeeperAddressSearch.js').then(
                  m => {
                    return {
                      namespace: 'shopkeeperAddressSearch',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__CadastrarLojista" */ '../CadastrarLojista'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../CadastrarLojista').default,
        exact: true,
      },
      {
        path: '/analise/lojista',
        authority: ['/analise/lojista'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__AnaliseLojista" */ '../AnaliseLojista'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../AnaliseLojista').default,
        exact: true,
      },
      {
        path: '/cliente',
        authority: ['/cliente'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Cliente" */ '../Cliente'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../Cliente').default,
        exact: true,
      },
      {
        path: '/cadastrar/cliente',
        authority: ['/cadastrar/cliente'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__CadastrarCliente" */ '../CadastrarCliente'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../CadastrarCliente').default,
        exact: true,
      },
      {
        path: '/cadastrar/cliente/:cnpj_cpf/:idanalisecredito',
        authority: ['/cadastrar/cliente/:cnpj_cpf/:idanalisecredito'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__CadastrarCliente" */ '../CadastrarCliente'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../CadastrarCliente').default,
        exact: true,
      },
      {
        path: '/analisar/lojista/:idcdclojista/:idcdccontratolojista',
        authority: ['/analisar/lojista/:idcdclojista/:idcdccontratolojista'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__AnalisarLojista" */ '../AnalisarLojista'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../AnalisarLojista').default,
        exact: true,
      },
      {
        path: '/cadastrar/usuario',
        authority: ['/cadastrar/usuario'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__CadastrarUsuario__models__permission.js' */ '/usr/src/app/src/pages/CadastrarUsuario/models/permission.js').then(
                  m => {
                    return { namespace: 'permission', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__CadastrarUsuario__models__user_register.js' */ '/usr/src/app/src/pages/CadastrarUsuario/models/user_register.js').then(
                  m => {
                    return { namespace: 'user_register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__CadastrarUsuario" */ '../CadastrarUsuario'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../CadastrarUsuario').default,
        exact: true,
      },
      {
        path: '/perfil',
        authority: ['/perfil'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__Profile__models__profile.js' */ '/usr/src/app/src/pages/Profile/models/profile.js').then(
                  m => {
                    return { namespace: 'profile', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Profile__models__route.js' */ '/usr/src/app/src/pages/Profile/models/route.js').then(
                  m => {
                    return { namespace: 'route', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__Profile" */ '../Profile'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../Profile').default,
        exact: true,
      },
      {
        path: '/pendencias',
        authority: ['/pendencias'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__Pendencias__models__pendingAccounts.js' */ '/usr/src/app/src/pages/Pendencias/models/pendingAccounts.js').then(
                  m => {
                    return { namespace: 'pendingAccounts', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__Pendencias__models__person.js' */ '/usr/src/app/src/pages/Pendencias/models/person.js').then(
                  m => {
                    return { namespace: 'person', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__Pendencias" */ '../Pendencias'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../Pendencias').default,
        exact: true,
      },
      {
        path: '/v2/simulacao',
        authority: ['/v2/simulacao'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Simulacao" */ '../Simulacao'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../Simulacao').default,
        exact: true,
      },
      {
        path: '/relatorio/propostas',
        authority: ['/relatorio/propostas'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__ProposalReports__models__fetchProposalsReportsForm.js' */ '/usr/src/app/src/pages/ProposalReports/models/fetchProposalsReportsForm.js').then(
                  m => {
                    return {
                      namespace: 'fetchProposalsReportsForm',
                      ...m.default,
                    };
                  },
                ),
                import(/* webpackChunkName: 'p__ProposalReports__models__proposalReports.js' */ '/usr/src/app/src/pages/ProposalReports/models/proposalReports.js').then(
                  m => {
                    return { namespace: 'proposalReports', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__ProposalReports__models__proposalReportsShopkeeper.js' */ '/usr/src/app/src/pages/ProposalReports/models/proposalReportsShopkeeper.js').then(
                  m => {
                    return {
                      namespace: 'proposalReportsShopkeeper',
                      ...m.default,
                    };
                  },
                ),
                import(/* webpackChunkName: 'p__ProposalReports__models__proposalReportsStatus.js' */ '/usr/src/app/src/pages/ProposalReports/models/proposalReportsStatus.js').then(
                  m => {
                    return { namespace: 'proposalReportsStatus', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__ProposalReports__models__proposalReportsUser.js' */ '/usr/src/app/src/pages/ProposalReports/models/proposalReportsUser.js').then(
                  m => {
                    return { namespace: 'proposalReportsUser', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__ProposalReports" */ '../ProposalReports'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../ProposalReports').default,
        exact: true,
      },
      {
        path: '/simulacao',
        authority: ['/simulacao'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Simulacao" */ '../Simulacao'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../Simulacao').default,
        exact: true,
      },
      {
        path: '/relatorios',
        authority: [
          '/relatorios',
          '/relatorios/ranking',
          '/relatorios/clientes',
          '/relatorios/lojista',
          '/relatorios/cliente',
          '/relatorios/bordero',
        ],
        routes: [
          {
            path: '/relatorios/ranking',
            authority: ['/relatorios/ranking'],
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Reports__models__getPDFResponse.js' */ '/usr/src/app/src/pages/Reports/models/getPDFResponse.js').then(
                      m => {
                        return { namespace: 'getPDFResponse', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getPropostaData.js' */ '/usr/src/app/src/pages/Reports/models/getPropostaData.js').then(
                      m => {
                        return { namespace: 'getPropostaData', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getSalesRank.js' */ '/usr/src/app/src/pages/Reports/models/getSalesRank.js').then(
                      m => {
                        return { namespace: 'getSalesRank', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getSituation.js' */ '/usr/src/app/src/pages/Reports/models/getSituation.js').then(
                      m => {
                        return { namespace: 'getSituation', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getTitleBordero.js' */ '/usr/src/app/src/pages/Reports/models/getTitleBordero.js').then(
                      m => {
                        return { namespace: 'getTitleBordero', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getTitleClient.js' */ '/usr/src/app/src/pages/Reports/models/getTitleClient.js').then(
                      m => {
                        return { namespace: 'getTitleClient', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getTitleRetailer.js' */ '/usr/src/app/src/pages/Reports/models/getTitleRetailer.js').then(
                      m => {
                        return { namespace: 'getTitleRetailer', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__modal.js' */ '/usr/src/app/src/pages/Reports/models/modal.js').then(
                      m => {
                        return { namespace: 'modal', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__retailer.js' */ '/usr/src/app/src/pages/Reports/models/retailer.js').then(
                      m => {
                        return { namespace: 'retailer', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Reports__Ranking" */ '../Reports/Ranking'),
                  LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../Reports/Ranking').default,
            exact: true,
          },
          {
            path: '/relatorios/clientes',
            authority: ['/relatorios/clientes'],
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Reports__ClientAnalysisReport__models__clientAnalysisReportForm.js' */ '/usr/src/app/src/pages/Reports/ClientAnalysisReport/models/clientAnalysisReportForm.js').then(
                      m => {
                        return {
                          namespace: 'clientAnalysisReportForm',
                          ...m.default,
                        };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__ClientAnalysisReport__models__clientAnalysisReportPage.js' */ '/usr/src/app/src/pages/Reports/ClientAnalysisReport/models/clientAnalysisReportPage.js').then(
                      m => {
                        return {
                          namespace: 'clientAnalysisReportPage',
                          ...m.default,
                        };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__ClientAnalysisReport__models__clientAnalysisReportPerson.js' */ '/usr/src/app/src/pages/Reports/ClientAnalysisReport/models/clientAnalysisReportPerson.js').then(
                      m => {
                        return {
                          namespace: 'clientAnalysisReportPerson',
                          ...m.default,
                        };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getPDFResponse.js' */ '/usr/src/app/src/pages/Reports/models/getPDFResponse.js').then(
                      m => {
                        return { namespace: 'getPDFResponse', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getPropostaData.js' */ '/usr/src/app/src/pages/Reports/models/getPropostaData.js').then(
                      m => {
                        return { namespace: 'getPropostaData', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getSalesRank.js' */ '/usr/src/app/src/pages/Reports/models/getSalesRank.js').then(
                      m => {
                        return { namespace: 'getSalesRank', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getSituation.js' */ '/usr/src/app/src/pages/Reports/models/getSituation.js').then(
                      m => {
                        return { namespace: 'getSituation', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getTitleBordero.js' */ '/usr/src/app/src/pages/Reports/models/getTitleBordero.js').then(
                      m => {
                        return { namespace: 'getTitleBordero', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getTitleClient.js' */ '/usr/src/app/src/pages/Reports/models/getTitleClient.js').then(
                      m => {
                        return { namespace: 'getTitleClient', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getTitleRetailer.js' */ '/usr/src/app/src/pages/Reports/models/getTitleRetailer.js').then(
                      m => {
                        return { namespace: 'getTitleRetailer', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__modal.js' */ '/usr/src/app/src/pages/Reports/models/modal.js').then(
                      m => {
                        return { namespace: 'modal', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__retailer.js' */ '/usr/src/app/src/pages/Reports/models/retailer.js').then(
                      m => {
                        return { namespace: 'retailer', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Reports__ClientAnalysisReport" */ '../Reports/ClientAnalysisReport'),
                  LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../Reports/ClientAnalysisReport').default,
            exact: true,
          },
          {
            path: '/relatorios/lojista',
            authority: ['/relatorios/lojista'],
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Reports__models__getPDFResponse.js' */ '/usr/src/app/src/pages/Reports/models/getPDFResponse.js').then(
                      m => {
                        return { namespace: 'getPDFResponse', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getPropostaData.js' */ '/usr/src/app/src/pages/Reports/models/getPropostaData.js').then(
                      m => {
                        return { namespace: 'getPropostaData', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getSalesRank.js' */ '/usr/src/app/src/pages/Reports/models/getSalesRank.js').then(
                      m => {
                        return { namespace: 'getSalesRank', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getSituation.js' */ '/usr/src/app/src/pages/Reports/models/getSituation.js').then(
                      m => {
                        return { namespace: 'getSituation', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getTitleBordero.js' */ '/usr/src/app/src/pages/Reports/models/getTitleBordero.js').then(
                      m => {
                        return { namespace: 'getTitleBordero', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getTitleClient.js' */ '/usr/src/app/src/pages/Reports/models/getTitleClient.js').then(
                      m => {
                        return { namespace: 'getTitleClient', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getTitleRetailer.js' */ '/usr/src/app/src/pages/Reports/models/getTitleRetailer.js').then(
                      m => {
                        return { namespace: 'getTitleRetailer', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__modal.js' */ '/usr/src/app/src/pages/Reports/models/modal.js').then(
                      m => {
                        return { namespace: 'modal', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__retailer.js' */ '/usr/src/app/src/pages/Reports/models/retailer.js').then(
                      m => {
                        return { namespace: 'retailer', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Reports__TituloLojista" */ '../Reports/TituloLojista'),
                  LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../Reports/TituloLojista').default,
            exact: true,
          },
          {
            path: '/relatorios/cliente',
            authority: ['/relatorios/cliente'],
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Reports__models__getPDFResponse.js' */ '/usr/src/app/src/pages/Reports/models/getPDFResponse.js').then(
                      m => {
                        return { namespace: 'getPDFResponse', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getPropostaData.js' */ '/usr/src/app/src/pages/Reports/models/getPropostaData.js').then(
                      m => {
                        return { namespace: 'getPropostaData', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getSalesRank.js' */ '/usr/src/app/src/pages/Reports/models/getSalesRank.js').then(
                      m => {
                        return { namespace: 'getSalesRank', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getSituation.js' */ '/usr/src/app/src/pages/Reports/models/getSituation.js').then(
                      m => {
                        return { namespace: 'getSituation', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getTitleBordero.js' */ '/usr/src/app/src/pages/Reports/models/getTitleBordero.js').then(
                      m => {
                        return { namespace: 'getTitleBordero', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getTitleClient.js' */ '/usr/src/app/src/pages/Reports/models/getTitleClient.js').then(
                      m => {
                        return { namespace: 'getTitleClient', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getTitleRetailer.js' */ '/usr/src/app/src/pages/Reports/models/getTitleRetailer.js').then(
                      m => {
                        return { namespace: 'getTitleRetailer', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__modal.js' */ '/usr/src/app/src/pages/Reports/models/modal.js').then(
                      m => {
                        return { namespace: 'modal', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__retailer.js' */ '/usr/src/app/src/pages/Reports/models/retailer.js').then(
                      m => {
                        return { namespace: 'retailer', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Reports__TituloCliente" */ '../Reports/TituloCliente'),
                  LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../Reports/TituloCliente').default,
            exact: true,
          },
          {
            path: '/relatorios/bordero',
            authority: ['/relatorios/bordero'],
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Reports__models__getPDFResponse.js' */ '/usr/src/app/src/pages/Reports/models/getPDFResponse.js').then(
                      m => {
                        return { namespace: 'getPDFResponse', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getPropostaData.js' */ '/usr/src/app/src/pages/Reports/models/getPropostaData.js').then(
                      m => {
                        return { namespace: 'getPropostaData', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getSalesRank.js' */ '/usr/src/app/src/pages/Reports/models/getSalesRank.js').then(
                      m => {
                        return { namespace: 'getSalesRank', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getSituation.js' */ '/usr/src/app/src/pages/Reports/models/getSituation.js').then(
                      m => {
                        return { namespace: 'getSituation', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getTitleBordero.js' */ '/usr/src/app/src/pages/Reports/models/getTitleBordero.js').then(
                      m => {
                        return { namespace: 'getTitleBordero', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getTitleClient.js' */ '/usr/src/app/src/pages/Reports/models/getTitleClient.js').then(
                      m => {
                        return { namespace: 'getTitleClient', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__getTitleRetailer.js' */ '/usr/src/app/src/pages/Reports/models/getTitleRetailer.js').then(
                      m => {
                        return { namespace: 'getTitleRetailer', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__modal.js' */ '/usr/src/app/src/pages/Reports/models/modal.js').then(
                      m => {
                        return { namespace: 'modal', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Reports__models__retailer.js' */ '/usr/src/app/src/pages/Reports/models/retailer.js').then(
                      m => {
                        return { namespace: 'retailer', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Reports__TituloBordero" */ '../Reports/TituloBordero'),
                  LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../Reports/TituloBordero').default,
            exact: true,
          },
        ],
      },
      {
        path: '/parametros',
        authority: ['/parametros'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__Parametros__models__generalParameters.js' */ '/usr/src/app/src/pages/Parametros/models/generalParameters.js').then(
                  m => {
                    return { namespace: 'generalParameters', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__Parametros" */ '../Parametros'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../Parametros').default,
        exact: true,
      },
      {
        path: '/',
        redirect: '/login',
        exact: true,
      },
      {
        path: '/404',
        name: '404',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        public: true,
        exact: true,
      },
      {
        path: '/403',
        name: '403',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__403" */ '../403'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../403').default,
        public: true,
        exact: true,
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
      },
    ],
  },
  {
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import(/* webpackChunkName: "p__404" */ '../404'),
          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
            .default,
        })
      : require('../404').default,
    exact: true,
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
