import axios from 'axios';
import { getToken } from '@/services/Controllers/Token';

export const FENIX_PROCESS_NOT_EXISTS = 1004;

const fenixInstance = axios.create({
    baseURL: CONFIG.FENIX_URL,
});

fenixInstance.interceptors.request.use(
    config => {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${getToken()}`;

        return config;
    },
    error => Promise.reject(error),
);

export default fenixInstance;
