import { Icon } from 'antd';
import { errorHandler } from '@/services/Request/error';
import { get_initial_messages, get_full_message } from '@/services/Request/noticeIcon';
import { Console } from '@/utils/Console';
import orderBy from 'lodash/orderBy';

let arrayMessage = [];
export default {
    namespace: 'model_amqp',
    state: {
        messageAMQP: [],
        initialMessages: [],
        messageFullData: undefined,
        loading: false,
    },
    reducers: {
        handlerSetInitMessages(state) {
            arrayMessage = [];
            return {
                ...state,
                messageAMQP: [],
            };
        },
        handlerSetInitModal(state) {
            return {
                ...state,
                messageFullData: undefined,
            };
        },
        handlerSetMessages(state, { payload: dados }) {
            arrayMessage = dados.map(message => ({
                id: message.idcdcmensagem,
                avatar: message.icone ? <Icon type={message.icone} /> : undefined,
                title: message.titulo,
                description: message.mensagem,
                datetime: message.dataenvio,
                read: message.visualizado,
                type: 'message',
            }));
            const array = orderBy(arrayMessage, ['id', 'datetime'], ['desc', 'asc']);
            return {
                ...state,
                messageAMQP: array,
            };
        },
        handlerSetFullMessage(state, { payload: dados }) {
            return {
                ...state,
                messageFullData: dados,
            };
        },
        setLoading(state, { payload: stateLoader }) {
            return {
                ...state,
                loading: stateLoader,
            };
        },
        saveNotices(state, { payload: dados }) {
            arrayMessage = dados;
            return {
                ...state,
                messageAMQP: dados,
            };
        },
        initialNotices(state, { payload: dados }) {
            return {
                ...state,
                initialMessages: dados,
            };
        },
        handlerRequestError(state, { payload: error }) {
            errorHandler(error);
            return {
                ...state,
            };
        },
    },
    effects: {
        *getFullMessage(_, { call, put }) {
            yield put({ type: 'setLoading', payload: true });
            try {
                const { message } = _.payload;
                const fullMessage = yield call(get_full_message, {
                    idcdcmensagem: message.id,
                });
                yield put({ type: 'handlerSetFullMessage', payload: fullMessage.data });
                yield put({ type: 'setLoading', payload: false });
            } catch (error) {
                Console(error);
                yield put({
                    type: 'handlerRequestError',
                    payload: error,
                });
                yield put({ type: 'setLoading', payload: false });
            }
        },
        *getInitialMessages(_, { call, put }) {
            try {
                const initialMessages = yield call(get_initial_messages);
                yield put({ type: 'initialNotices', payload: initialMessages.data });
                yield put({ type: 'spin/setSpinning', payload: false });
            } catch (error) {
                Console(error);
                yield put({
                    type: 'handlerRequestError',
                    payload: error,
                });
            }
        },
        *changeNoticeReadState(_, { put, select }) {
            yield put({ type: 'spin/setSpinning', payload: true });
            try {
                const { id } = _.payload;
                const messageRead = yield select(state =>
                    state.model_amqp.messageAMQP.map(item => {
                        const notice = { ...item };

                        if (notice.id === id) {
                            notice.read = true;
                        }

                        return notice;
                    }),
                );
                yield put({ type: 'saveNotices', payload: messageRead });
                yield put({ type: 'spin/setSpinning', payload: false });
            } catch (error) {
                Console(error);
                yield put({
                    type: 'handlerRequestError',
                    payload: error,
                });
                yield put({ type: 'spin/setSpinning', payload: false });
            }

            yield put({
                type: 'handlerRequestLoading',
                requestLoading: false,
            });
        },
    },
};
