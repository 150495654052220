export default {
    namespace: 'tab',
    state: {
        currentTab: '1',
    },
    reducers: {
        initTabs(state) {
            return {
                ...state,
                currentTab: '1',
            };
        },
        setCurrentTab(state, { payload: currentTab }) {
            return {
                ...state,
                currentTab,
            };
        },
    },
};
