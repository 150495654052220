import LocalStorage from './LocalStorage';

export default class LocalStorageAuxiliaries extends LocalStorage {
    constructor() {
        super(LOCAL_STORAGE_KEYS.AUXILIARIES);
    }

    static setData = data => super.setLocalStorageData(LOCAL_STORAGE_KEYS.AUXILIARIES, data);

    static getData = () => super.getLocalStorageData(LOCAL_STORAGE_KEYS.AUXILIARIES);

    static getGzTimeFutebol = () => this.getData()['gazin.gztimefutebol'];

    static getAuditoriaCampo = () => this.getData()['gazin.siga_auditoriacampo'];

    static getClassificacaoCliente = () => this.getData()['gazin.siga_classificacao_cliente'];

    static getTipoUrgenciaAnalise = () => this.getData()['gazin.siga_tipo_urgencia_analise'];

    static getGrauParentesco = () => this.getData()['sis.grauparentesco'];

    static getTipoOcupacao = () => this.getData()['sis.tipoocupacao'];

    static getTipoPessoa = () => this.getData()['sis.tipopessoa'];

    static getTipoResidencia = () => this.getData()['sis.tiporesidencia'];

    static getTipoSexo = () => this.getData()['sis.tiposexo'];

    static getTipoTelefone = () => this.getData()['sis.tipotelefone'];

    static getNivelFormacao = () => this.getData()['sis.nivelformacao'];

    static getTipoCadastro = () => this.getData()['sis.tipocadastro'];

    static getTipoContribuinte = () => this.getData()['sis.tipocontribuinte'];

    static getTipoEndereco = () => this.getData()['sis.tipoendereco'];

    static getTipoEstadoCivil = () => this.getData()['sis.tipoestadocivil'];
}
