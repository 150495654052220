import * as Sentry from '@sentry/react';
import { Notification } from '@/components/Modal/Notification';

export const getErrorAttributes = error => {
    let errorAttributes = {
        description: '',
        message: '',
        code: '',
    };

    if (error.response?.data?.error) {
        errorAttributes = {
            description: error.response.data.error.description,
            message: error.response.data.error.message,
            code: error.response.data.error.code,
        };
    } else if (error.response?.data?.erro) {
        errorAttributes = {
            description: error.response.data.erro.description
                ? error.response.data.erro.description
                : error.response.data.erro,
            message: error.response.statusText,
            code: error.response.status,
        };
    }
    if (errorAttributes && errorAttributes?.message.toUpperCase() === 'INTERNAL SERVER ERROR') {
        errorAttributes.message = 'Erro ao processar solicitação';
    }

    return errorAttributes;
};

export const errorHandler = error => {
    const { description, message, code } = getErrorAttributes(error);

    if (!description) {
        Sentry.captureException(error);
    }

    Notification(PROJECT_MESSAGE.ERROR_TYPE_MESSAGE, `ERRO (${code}): ${message}`, description, 8);
};
