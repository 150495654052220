export default {
  'menu.Login': 'Login',
  'menu.welcome': 'Welcome',
  'menu.CadastroLojista': 'Lojista',
  'menu.Token': 'Token',
  'menu.CPF': 'CPF',
  'menu.Lojista': 'Lojista',
  'menu.CadastroCliente': 'Cliente',
  'menu.Cliente': 'Correção de Cadastro',
  'menu.CadastroUsuario': 'Usuário',
  'menu.Usuário': 'Usuário',
  'menu.Cadastrar Usuário': 'Usuário',
  'menu.Simulaçao': 'Nova Venda',
  'menu.Simulação': 'Nova Venda',
  'menu.Inicio': 'Início',
  'menu.Recuperar senha': 'Recuperar senha',
  'menu.Proposta': 'Acompanhamento de Venda',
  'menu.Contrato': 'Contrato',
  'menu.Análise Lojista': 'Análise Lojista',
  'menu.Analisar Lojista': 'Analisar Lojista',
  'menu.Rota': 'Rota',
  'menu.Perfil': 'Perfil',
  'menu.Pendências': '2ª via de boleto',

  'menu.more-blocks': 'More Blocks',
  'menu.home': 'Início',
  'menu.login': 'Login',
  'menu.register': 'Registro',
  'menu.register.result': 'Resultado de registro',
  'menu.Dashboard': 'Tela Inicial',
  'menu.Dashboard.analysis': 'Análise',
  'menu.Dashboard.monitor': 'Monitor',
  'menu.Dashboard.workplace': 'Ambiente de Trabalho',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Formulário',
  'menu.form.basic-form': 'Formulário Básico',
  'menu.form.step-form': 'Formulário Assistido',
  'menu.form.step-form.info': 'Formulário Assistido(gravar informações de transferência)',
  'menu.form.step-form.confirm': 'Formulário Assistido(confirmar informações de transferência)',
  'menu.form.step-form.result': 'Formulário Assistido(finalizado)',
  'menu.form.advanced-form': 'Formulário Avançado',
  'menu.list': 'Lista',
  'menu.list.table-list': 'Tabela de Busca',
  'menu.list.basic-list': 'Lista Básica',
  'menu.list.card-list': 'Lista de Card',
  'menu.list.search-list': 'Lista de Busca',
  'menu.list.search-list.articles': 'Lista de Busca(artigos)',
  'menu.list.search-list.projects': 'Lista de Busca(projetos)',
  'menu.list.search-list.applications': 'Lista de Busca(aplicações)',
  'menu.profile': 'Perfil',
  'menu.profile.basic': 'Perfil Básico',
  'menu.profile.advanced': 'Perfil Avançado',
  'menu.result': 'Resultado',
  'menu.result.success': 'Sucesso',
  'menu.result.fail': 'Falha',
  'menu.exception': 'Exceção',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Disparar',
  'menu.account': 'Conta',
  'menu.account.center': 'Central da conta',
  'menu.account.settings': 'Configurar conta',
  'menu.account.trigger': 'Disparar Erro',
  'menu.account.logout': 'Sair',
  'menu.account.resetPassword': 'Redefinir senha',
  'menu.account.changeUser': 'Mudar usuário',
  'menu.account.disconnect': 'Desconectar',
  'menu.editor': 'Graphic Editor',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',
  'menu.Mensagem': 'Mensagem',

  'menu.Relatório': 'Relatório',
  'menu.Relatório.Titulo Clientes': 'Titulo Clientes',
  'menu.Relatório.Títulos Lojistas': 'Títulos Lojistas',
  'menu.Relatório.Títulos Borderô': 'Títulos Borderô',
  'menu.Relatório.Ranking de vendas': 'Ranking de vendas',
  'menu.Relatório.Análise de clientes': 'Análise de clientes',
  'menu.Relatório.Título Clientes': 'Título Clientes',
  'menu.404': '404',
  'menu.403': '403',
};
