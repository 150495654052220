import LocalStorage from './LocalStorage';

const key = 'antd-pro-authority';
export default class LocalStorageAuthority extends LocalStorage {
    constructor() {
        super(key);
    }

    static getLocalStorageAuthority = () => {
        super.setKey(key);
        return super.getLocalStorageData(key);
    };

    static setLocalStorageAuthority = value => {
        super.setLocalStorageData(key, value);
    };
}
