const img = <img style={{ height: 22 }} src="nullrisk_header.png" />
export default {
  navTheme: 'dark',
  primaryColor: '#1890FF',
  layout: '1890FF',
  contentWidth: 'Fluid',
  fixedHeader: true,
  autoHideHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  menu: {
    locale: true,
  },
  title: img,
  pwa: false,
  iconfontUrl: '',
};
