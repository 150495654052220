import { urlBaseAuxiliares } from '@/utils/Requests/url';
import { getToken } from '@/services/Controllers/Token';
import { request } from './request';

export const getAuxiliaryData = () => {
    const options = {
        method: 'get',
        url: urlBaseAuxiliares,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    return request(options);
};
