export { default as LocalStorage } from './LocalStorage';
export { default as LocalStorageAuxiliaries } from './LocalStorageAuxiliaries';
export { default as LocalStorageCurrentPage } from './LocalStorageCurrentPage';
export { default as LocalStoragePerson } from './LocalStoragePerson';
export { default as LocalStorageStepTab } from './LocalStorageStepTab';
export { default as LocalStorageSimulation } from './LocalStorageSimulation';
export { default as LocalStoragePersonRegistration } from './LocalStoragePersonRegistration';
export { default as LocalStorageFinishingData } from './LocalStorageFinishingData';
export { default as LocalStorageAuthority } from './LocalStorageAuthority';
export { default as LocalStorageSiga } from './LocalStorageSiga';
export { default as LocalStorageFactory } from './LocalStorageFactory';
export { default as LocalStorageSimulationData } from './LocalStorageSimulationData';
