
const DOCUMENT_TYPES_SAFE_DOC = {
    'FOTO DO CLIENTE': {
        captureKey: 'Foto do Cliente',
        id: '1',
        safeDockKey: 'FOTO DO CLIENTE',
        nullriskKey: ['FOTO_DO_CLIENTE'],
        allowUpload: false,
        required: true,
        stayHide: false,
    },
    RG: {
        captureKey: 'RG',
        id: '2',
        safeDockKey: 'RG',
        nullriskKey: ['RG'],
        frontId: '501',
        backId: '502',
        allowUpload: false,
        required: true,
        stayHide: false,
    },
    'RG Frente': {
        captureKey: 'RG Frente',
        id: '501',
        safeDockKey: 'RG Frente',
        nullriskKey: ['RG'],
        allowUpload: false,
        required: true,
        stayHide: false,
    },
    'RG Verso': {
        captureKey: 'RG Verso',
        id: '502',
        safeDockKey: 'RG Verso',
        nullriskKey: ['RG'],
        allowUpload: false,
        required: true,
        stayHide: false,
    },

    CPF: {
        captureKey: 'CPF',
        id: '3',
        safeDockKey: 'CPF',
        nullriskKey: ['CPF'],
        allowUpload: true,
        required: true,
        stayHide: false,
    },
    CNH: {
        captureKey: 'CNH',
        id: '4',
        safeDockKey: 'CNH',
        nullriskKey: ['CNH'],
        allowUpload: false,
        required: true,
        stayHide: false,
    },
    'COMPROVANTE DE RENDA': {
        captureKey: 'Comprovante de Renda',
        id: '5',
        safeDockKey: 'COMPROVANTE DE RENDA',
        nullriskKey: ['COMPROVANTE_DE_RENDA', 'IMPOSTO_RENDA'],
        allowUpload: true,
        required: true,
        stayHide: false,
    },
    'COMPROVANTE DE RESIDÊNCIA': {
        captureKey: 'Comprovante de Endereço',
        id: '6',
        safeDockKey: 'COMPROVANTE DE RESIDÊNCIA',
        nullriskKey: ['COMPROVANTE_DE_RESIDENCIA'],
        allowUpload: true,
        required: true,
        stayHide: false,
    },
    'COMPROVANTE DE ENDEREÇO': {
        captureKey: 'Comprovante de Endereço',
        id: '6',
        safeDockKey: 'COMPROVANTE DE ENDEREÇO',
        nullriskKey: ['COMPROVANTE_DE_ENDERECO'],
        allowUpload: true,
        required: true,
        stayHide: false,
    },
    'CARTEIRA DE TRABALHO': {
        captureKey: 'Carteira de Trabalho',
        id: '20',
        safeDockKey: 'CARTEIRA DE TRABALHO',
        nullriskKey: ['CARTEIRA_DE_TRABALHO'],
        allowUpload: true,
        required: false,
        stayHide: false,
    },
    CTPS: {
        captureKey: 'CTPS',
        id: '22',
        safeDockKey: 'CTPS',
        nullriskKey: ['CTPS'],
        allowUpload: true,
        required: false,
        stayHide: false,
    },
    'CERTIDÃO DE ÓBITO': {
        captureKey: 'Certidão de Óbito',
        id: '9',
        safeDockKey: 'CERTIDÃO DE ÓBITO',
        nullriskKey: ['CERTIDAO_DE_OBITO'],
        allowUpload: true,
        required: false,
        stayHide: false,
    },
    'TERMO DE CONSENTIMENTO': {
        captureKey: 'Termo de Consentimento',
        id: '114',
        safeDockKey: 'TERMO DE CONSENTIMENTO',
        nullriskKey: ['TERMO_DE_CONSENTIMENTO'],
        allowUpload: true,
        required: true,
        stayHide: false,
    },
    PROPOSTA: {
        captureKey: 'Proposta',
        id: '300',
        safeDockKey: 'PROPOSTA',
        nullriskKey: ['PROPOSTA'],
        allowUpload: true,
        required: true,
        stayHide: false,
    },
    'PROPOSTA DE CONTRATO': {
        captureKey: 'Proposta de Contrato',
        id: '301',
        safeDockKey: 'PROPOSTA DE CONTRATO',
        nullriskKey: ['PROPOSTA_DE_CONTRATO'],
        allowUpload: true,
        required: true,
        stayHide: false,
    },
};

const TIPO_OCUPACAO = {
    ASSALARIADO: '1',
};

const UNICO_PROCESS_STATUS = {
    AGUARDANDO: 1,
    DIVERGENCIA: 2,
    CONCLUIDO: 3,
    CANCELADO: 4,
    ERRO: 5,
    ENVIANDO_MENSAGEM: 6,
    REENVIANDO_MENSAGEM: 8
}

module.exports = {
    DOCUMENT_TYPES_SAFE_DOC,
    TIPO_OCUPACAO,
    UNICO_PROCESS_STATUS
};
