export default class SessionStorage {
    static setSessionStorageData = (key, value) => {
        sessionStorage.setItem(key, value);
    };

    static getSessionStorageData = key => sessionStorage.getItem(key);

    static setSessionStorageObjectData = (key, value) => {
        sessionStorage.setItem(key, JSON.stringify(value));
    };

    static getSessionStorageObjectData = key => JSON.parse(sessionStorage.getItem(key));

    static removeItem = key => {
        sessionStorage.removeItem(key);
    };
}
