import { isArray } from 'util';
import { isObject, isDefined } from '@/utils/checkDataTypes';
import { LocalStorage } from '@/services/LocalStorage';
import { SessionStorageTabControl } from '@/services/SessionStorage';

import ls from 'local-storage';

export default class LocalStorageSiga extends LocalStorage {
    constructor() {
        super(LOCAL_STORAGE_KEYS.SIGA);
    }

    static getKey = () => LOCAL_STORAGE_KEYS.SIGA;

    static getData = localStorageEntity => {
        const currentPersonKey = SessionStorageTabControl.getData(localStorageEntity);
        const localStorageData = localStorageEntity.getLocalStorageData();

        if (localStorageData[currentPersonKey]) {
            return localStorageData[currentPersonKey][LOCAL_STORAGE_KEYS.SIGA];
        }

        return undefined;
    };

    static getDataByIdCnpjCpf = (localStorageEntity, idCnpjCpf) => {
        const localStorageData = localStorageEntity.getLocalStorageData();
        const newLocalStorageData = localStorageData[idCnpjCpf]
            ? localStorageData[idCnpjCpf][LOCAL_STORAGE_KEYS.SIGA]
            : {};
        return newLocalStorageData;
    };

    static getCpf_Cnpj = localStorageEntity => {
        const currentPersonKey = SessionStorageTabControl.getData(localStorageEntity);
        const localStorageData = localStorageEntity.getLocalStorageData();
        return localStorageData[currentPersonKey][LOCAL_STORAGE_KEYS.SIGA].cnpj_cpf;
    };

    static setData = (localStorageEntity, value) => {
        const currentPersonKey = SessionStorageTabControl.getData(localStorageEntity);
        const localStorageData = localStorageEntity.getLocalStorageData();
        const localStorageKey = localStorageEntity.getKey();
        (localStorageData[currentPersonKey] || {})[LOCAL_STORAGE_KEYS.SIGA] = value;
        ls.set(localStorageKey, localStorageData);
    };

    static localStorageInit = (localStorageEntity, cnpj_cpf = undefined) => {
        const personData = {
            analise: {},
            dadosempregaticio: [],
            dependente: [],
            endereco: [],
            gzdadosfamiliar: [],
            gzpessoa: {},
            idtipocadastro: '2',
            idtipocontribuinte: '2',
            idtipopessoa: '1',
            cnpj_cpf,
            pessoaauxiliar: {},
        };

        this.setData(localStorageEntity, personData);
    };

    static setPersonToLocalStorage = ngStoragePessoa => {
        super.setLocalStorageData(this.key, ngStoragePessoa);
    };

    static setPersonLocalStorage = (localStorageEntity, pessoa) => {
        const ngStoragePessoa = { ...pessoa } || {};
        if (ngStoragePessoa.idtipopessoa === '1' && !ngStoragePessoa.dadosnivelinstrucao) {
            ngStoragePessoa.dadosnivelinstrucao =
                isDefined(ngStoragePessoa.dadosnivelinstrucao) &&
                ngStoragePessoa.dadosnivelinstrucao
                    ? ngStoragePessoa.dadosnivelinstrucao
                    : {};
            ngStoragePessoa.dadosnivelinstrucao.idcnpj_cpf = ngStoragePessoa.idcnpj_cpf;
        }
        ngStoragePessoa.idtipocadastro = `${ngStoragePessoa.idtipocadastro || ''}`.match('^[12]$')
            ? ngStoragePessoa.idtipocadastro
            : '2';
        ngStoragePessoa.dependente = isArray(ngStoragePessoa.dependente)
            ? ngStoragePessoa.dependente
            : [];
        ngStoragePessoa.endereco = isArray(ngStoragePessoa.endereco)
            ? ngStoragePessoa.endereco
            : [];
        ngStoragePessoa.gzdadosfamiliar = isArray(ngStoragePessoa.gzdadosfamiliar)
            ? ngStoragePessoa.gzdadosfamiliar
            : [];
        ngStoragePessoa.referenciacomercial = isArray(ngStoragePessoa.referenciacomercial)
            ? ngStoragePessoa.referenciacomercial
            : [];
        ngStoragePessoa.dadosempregaticio = isArray(ngStoragePessoa.dadosempregaticio)
            ? ngStoragePessoa.dadosempregaticio
            : [];
        ngStoragePessoa.analise = isObject(ngStoragePessoa.analise) ? ngStoragePessoa.analise : {};
        this.setData(localStorageEntity, ngStoragePessoa);
    };

    static envelopePersonToLocal = dataToSave => {
        if (!isObject(dataToSave) || !isArray(dataToSave.pessoa) || dataToSave.pessoa.length == 0) {
            return undefined;
        }

        const newTypePerson = dataToSave.pessoa[0];
        newTypePerson.dependente = dataToSave.dependente;
        newTypePerson.referenciacomercial = dataToSave.referenciacomercial;

        newTypePerson.gzdadosfamiliar = dataToSave.gzdadosfamiliar;
        newTypePerson.gzpessoa =
            dataToSave.gzpessoa && dataToSave.gzpessoa.length > 0 ? dataToSave.gzpessoa[0] : {};
        newTypePerson.dadosnivelinstrucao =
            dataToSave.dadosnivelinstrucao && dataToSave.dadosnivelinstrucao.length > 0
                ? dataToSave.dadosnivelinstrucao[0]
                : {};
        newTypePerson.dadosempregaticio = dataToSave.dadosempregaticio;
        newTypePerson.pessoaauxiliar =
            dataToSave.pessoaauxiliar.length > 0 ? dataToSave.pessoaauxiliar[0] : {};
        newTypePerson.telefone = dataToSave.telefone;
        newTypePerson.endereco = dataToSave.endereco;
        newTypePerson.reanalise = false;

        if (['10', '11', '12'].indexOf(newTypePerson.idtipoocupacao || '0') !== -1) {
            delete newTypePerson.idtipoocupacao;
        }

        if (
            newTypePerson.gzpessoa &&
            newTypePerson.gzpessoa.idtipourgencia &&
            ['1', '5'].indexOf(newTypePerson.gzpessoa.idtipourgencia) === -1
        ) {
            delete newTypePerson.gzpessoa.idtipourgencia;
        }

        newTypePerson.temp = {};

        if (isArray(dataToSave.chaveliberacao) && dataToSave.chaveliberacao.length > 0) {
            newTypePerson.temp.chaveacesso = {};
            dataToSave.chaveliberacao.map(chave => {
                if (!Number(chave.idtipoliberacao)) {
                    return;
                }
                newTypePerson.temp.chaveacesso[chave.idtipoliberacao] = chave;
            });
        }

        newTypePerson.temp.cobradoras_email = isArray(dataToSave.cobradoras_email)
            ? dataToSave.cobradoras_email
            : [];
        newTypePerson.temp.cobradoras_endereco = isArray(dataToSave.cobradoras_endereco)
            ? dataToSave.cobradoras_endereco
            : [];
        newTypePerson.temp.cobradoras_telefone = isArray(dataToSave.cobradoras_telefone)
            ? dataToSave.cobradoras_telefone
            : [];

        return newTypePerson;
    };

    static initTipoUrgencia = (localStorageData, pessoa) => {
        const ngStoragePessoa = { ...pessoa };
        if (ngStoragePessoa) {
            if (!isObject(ngStoragePessoa.gzpessoa)) {
                ngStoragePessoa.gzpessoa = {};
                ngStoragePessoa.gzpessoa.idcnpj_cpf = ngStoragePessoa.idcnpj_cpf;
                this.setData(localStorageData, ngStoragePessoa);
            }
        }
        return ngStoragePessoa;
    };
}
