export default {
    namespace: 'url',
    state: {
        params: {},
    },
    reducers: {
        setUrlParams(state, { payload: params }) {
            return {
                ...state,
                params,
            };
        },
    },
    effects: {
        *setParams(payload, { put }) {
            yield put({
                type: 'setUrlParams',
                payload: payload.payload,
            });
        },
    },
};
