import dva from 'dva';
import { Component } from 'react';
import createLoading from 'dva-loading';
import history from '@tmp/history';

let app = null;

export function _onCreate() {
  const plugins = require('umi/_runtimePlugin');
  const runtimeDva = plugins.mergeConfig('dva');
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    ...(window.g_useSSR ? { initialState: window.g_initialData } : {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach(plugin => {
    app.use(plugin);
  });
  
  app.model({ namespace: 'index', ...(require('/usr/src/app/src/models/AMQP/index.js').default) });
app.model({ namespace: 'index', ...(require('/usr/src/app/src/models/AnalisarLojista/index.js').default) });
app.model({ namespace: 'index', ...(require('/usr/src/app/src/models/AnaliseLojista/index.js').default) });
app.model({ namespace: 'index', ...(require('/usr/src/app/src/models/Auxiliaries/index.js').default) });
app.model({ namespace: 'index', ...(require('/usr/src/app/src/models/CadastroCliente/Arkivus/index.js').default) });
app.model({ namespace: 'index', ...(require('/usr/src/app/src/models/CadastroCliente/Auxiliares/index.js').default) });
app.model({ namespace: 'index', ...(require('/usr/src/app/src/models/CadastroCliente/CheckList/index.jsx').default) });
app.model({ namespace: 'index', ...(require('/usr/src/app/src/models/CadastroCliente/CreditProtection/index.js').default) });
app.model({ namespace: 'index', ...(require('/usr/src/app/src/models/CadastroCliente/Customer/index.js').default) });
app.model({ namespace: 'DependentPerson', ...(require('/usr/src/app/src/models/CadastroCliente/PersonalData/DependentPerson.jsx').default) });
app.model({ namespace: 'Membership', ...(require('/usr/src/app/src/models/CadastroCliente/PersonalData/Membership.jsx').default) });
app.model({ namespace: 'References', ...(require('/usr/src/app/src/models/CadastroCliente/PersonalData/References.jsx').default) });
app.model({ namespace: 'index', ...(require('/usr/src/app/src/models/CadastroLojista/index.js').default) });
app.model({ namespace: 'index', ...(require('/usr/src/app/src/models/ChangeUser/index.js').default) });
app.model({ namespace: 'model', ...(require('/usr/src/app/src/models/Contrato/model.js').default) });
app.model({ namespace: 'index', ...(require('/usr/src/app/src/models/DashBoard/index.js').default) });
app.model({ namespace: 'defaultStepProgress', ...(require('/usr/src/app/src/models/defaultStepProgress.js').default) });
app.model({ namespace: 'global', ...(require('/usr/src/app/src/models/global.js').default) });
app.model({ namespace: 'index', ...(require('/usr/src/app/src/models/LocalStorage/index.js').default) });
app.model({ namespace: 'model', ...(require('/usr/src/app/src/models/Location/model.js').default) });
app.model({ namespace: 'login', ...(require('/usr/src/app/src/models/login.js').default) });
app.model({ namespace: 'index', ...(require('/usr/src/app/src/models/Membership/index.js').default) });
app.model({ namespace: 'modal', ...(require('/usr/src/app/src/models/modal.js').default) });
app.model({ namespace: 'index', ...(require('/usr/src/app/src/models/Routes/index.js').default) });
app.model({ namespace: 'setting', ...(require('/usr/src/app/src/models/setting.js').default) });
app.model({ namespace: 'index', ...(require('/usr/src/app/src/models/Simulacao/index.jsx').default) });
app.model({ namespace: 'spin', ...(require('/usr/src/app/src/models/spin.js').default) });
app.model({ namespace: 'stepProgressControl', ...(require('/usr/src/app/src/models/stepProgressControl.js').default) });
app.model({ namespace: 'index', ...(require('/usr/src/app/src/models/System/index.js').default) });
app.model({ namespace: 'index', ...(require('/usr/src/app/src/models/Tab/index.js').default) });
app.model({ namespace: 'url', ...(require('/usr/src/app/src/models/url.js').default) });
app.model({ namespace: 'user', ...(require('/usr/src/app/src/models/user.js').default) });
  return app;
}

export function getApp() {
  return app;
}

export class _DvaContainer extends Component {
  render() {
    const app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
