import { decodeTokenAPI } from '@/services/Controllers/Token';

export const indicadorToColor = indicador => {
    switch (indicador) {
        case 'analisado':
            return 'green';
        case 'pen-reanalize':
            return 'orange';
        case 'analisado_zerado':
            return 'red';
        case 'inativo':
            return '#000';
        case 'em-analise-loja':
            return 'blue';
        case 'ag-env-matriz':
            return '#F3F308';
        case 'pen-analise-matriz':
            return '#F3F308';
        case 'em-analise-matriz':
            return '#F3F308';
        case 'ag-cad':
            return 'transparent';
        case 'ag-recad':
            return 'transparent';
        default:
            return 'transparent';
    }
};


export const loadDisabledProps = () => {
    disableProps = { isDisabled: adminUserAccess() }
}

export const adminUserAccess = () => decodeTokenAPI().perfil?.tipoperfil === TIPO_PERFIL.ADMIN;

export let disableProps = { isDisabled: adminUserAccess() };

export const CLIENT_STATUS = {
    ACTIVE: '1',
    INACTIVE: '2',
}
