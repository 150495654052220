import omit from 'lodash/omit';
import SessionStorage from './SessionStorage';

export default class SessionStorageSimulationData extends SessionStorage {
    static setData = (localStorageEntity, data) => {
        const sessionStorageKey = localStorageEntity.getKey();
        const sessionStorageData = super.getSessionStorageObjectData(sessionStorageKey) || {};
        sessionStorageData[SESSION_STORAGE_KEYS.SIMULATION_DATA] = data;
        super.setSessionStorageObjectData(sessionStorageKey, sessionStorageData);
    };

    static getData = localStorageEntity => {
        const sessionStorageKey = localStorageEntity.getKey();
        const sessionStorageData = super.getSessionStorageObjectData(sessionStorageKey);
        return sessionStorageData ? sessionStorageData[SESSION_STORAGE_KEYS.SIMULATION_DATA] : null;
    };

    static removeData = localStorageEntity => {
        const sessionStorageKey = localStorageEntity.getKey();
        const sessionStorageData = super.getSessionStorageObjectData(sessionStorageKey);
        const newSessionStorageData = omit(
            sessionStorageData,
            SESSION_STORAGE_KEYS.SIMULATION_DATA,
        );
        super.setSessionStorageObjectData(sessionStorageKey, newSessionStorageData);
    };
}
