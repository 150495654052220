export const pages = {
    SIMULACAO: 'SIMULACAO',
    CHECKLIST: 'CHECKLIST',
    EPC: 'EPC',
    ARKIVUS: 'ARKIVUS',
    DADOS_PRINCIPAIS: 'DADOS_PRINCIPAIS',
    DADOS_FILIACAO: 'DADOS_FILIACAO',
    DADOS_LOCALIZACAO: 'DADOS_LOCALIZACAO',
    DADOS_CONTATO: 'DADOS_CONTATO',
    DADOS_REFERENCIA: 'DADOS_REFERENCIA',
    DADOS_TRABALHO: 'DADOS_TRABALHO',
    DADOS_DEPENDENTE: 'DADOS_DEPENDENTE',
    LIMITE_CREDITO: 'LIMITE_CREDITO',
    LIMITE_CREDITO2: 'LIMITE_CREDITO2',
    FINALIZACAO: 'FINALIZACAO',
};
