import localStorage from 'local-storage';

export default {
    namespace: 'local_storage',
    state: {
        key: '',
        localStorageData: {},
    },
    reducers: {
        setLocalKey(state, { payload: key }) {
            return {
                ...state,
                key,
            };
        },
        setData(state, { payload: data }) {
            localStorage.set(state.key, data);
            return {
                ...state,
                localStorageData: data,
            };
        },
    },
    effects: {
        *setKey({ payload }, { put }) {
            yield put({
                type: 'setLocalKey',
                payload,
            });
        },
    },
};
