import { getToken } from '@/services/Controllers/Token';
import { urlGetInitialMessage, urlGetFullMessage } from '../../utils/Requests/url';
import { request } from './request';

export const get_initial_messages = async () => {
    const options = {
        method: 'GET',
        url: urlGetInitialMessage,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    return request(options);
};

export const get_full_message = async data => {
    const { idcdcmensagem } = data;
    const options = {
        method: 'GET',
        url: urlGetFullMessage(idcdcmensagem),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    return request(options);
};
