import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
    enabled: CONFIG.ENVIRONMENT !== 'development',
    dsn: CONFIG.SENTRY.dsn,
    tracesSampleRate: 1.0,
    autoSessionTracking: true,
    environment: CONFIG.ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],
    beforeSend(event) {
        Notification(
            'error',
            'Ocorreu um erro na aplicação!',
            'Nossos desenvolvedores já estão trabalhando para resolver o problema.',
        );
        return event;
    },
});
