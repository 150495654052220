import { connect } from 'dva';
import { isArray } from 'util';
import { isObject, isDefined } from '@/utils/checkDataTypes';
import { LocalStorage, LocalStorageSiga, LocalStorageStepTab } from '@/services/LocalStorage';
import { SessionStorageTabControl } from '@/services/SessionStorage';
import { isCPF } from 'brazilian-values';

const ls = require('local-storage');
const uuidv1 = require('uuid/v1');
const _ = require('lodash');

class LocalStoragePerson extends LocalStorage {
    constructor(key, dispatch) {
        super(key);
        this.key = '';
    }

    static setInitialKey = dispatch => {
        this.setKey(dispatch, uuidv1());
    };

    static setKey = (dispatch, key) => {
        this.key = key;
        dispatch({
            type: 'local_storage/setKey',
            payload: key,
        });
    };

    static getKey = () => this.key;

    static getData = localStorageEntity => {
        const currentPersonKey = SessionStorageTabControl.getData(localStorageEntity);
        const localStorageData = localStorageEntity.getLocalStorageData();
        return localStorageData[currentPersonKey];
    };

    static getNotCnpjCpfKey = localStorageEntity => {
        const currentPersonKey = SessionStorageTabControl.getData(localStorageEntity);

        if (!isCPF(currentPersonKey)) {
            return currentPersonKey;
        }
        const localStorageData = localStorageEntity.getLocalStorageData();
        const keys = _.keysIn(localStorageData);
        return _.find(keys, key => !isCPF(key));
    };

    static setData = (localStorageEntity, value) => {
        const currentPersonKey = SessionStorageTabControl.getData(localStorageEntity);
        const localStorageData = localStorageEntity.getLocalStorageData();
        const localStorageKey = localStorageEntity.getKey();
        localStorageData[currentPersonKey] = value;
        super.setExpirationTime(localStorageData[currentPersonKey]);
        super.setUserId(localStorageData[currentPersonKey]);
        ls.set(localStorageKey, localStorageData);
    };

    static localStorageInitPerson = localStorageEntity => {
        const personData = {};
        // personData[LocalStorageSiga.getKey()] = LocalStorageSiga.localStorageInit();
        this.setData(localStorageEntity, personData);
        return personData;
    };

    static changeKey = (localStorageEntity, dispatch, newKey) => {
        const currentPersonKey = SessionStorageTabControl.getData(localStorageEntity);
        const localStorageData = localStorageEntity.getLocalStorageData();
        const personData = localStorageData[currentPersonKey];
        let newLocalStorageData = null;
        if (!isCPF(currentPersonKey)) {
            newLocalStorageData = _.omit(localStorageData, currentPersonKey);
        } else {
            newLocalStorageData = localStorageData;
        }
        newLocalStorageData[newKey] = personData;
        const localStorageKey = localStorageEntity.getKey();
        this.setKey(dispatch, newKey);
        SessionStorageTabControl.setData(localStorageEntity, newKey);
        ls.set(localStorageKey, newLocalStorageData);
    };

    static deleteCurrent = (localStorageEntity, dispatch) => {
        const currentPersonKey = SessionStorageTabControl.getData(localStorageEntity);
        const localStorageData = localStorageEntity.getLocalStorageData();
        let newLocalStorageData = null;
        newLocalStorageData = _.omit(localStorageData, currentPersonKey);
        const localStorageKey = localStorageEntity.getKey();
        const newKey = uuidv1();
        this.setKey(dispatch, newKey);
        SessionStorageTabControl.setData(localStorageEntity, newKey);
        ls.set(localStorageKey, newLocalStorageData);
    };

    static deleteByIdCnpjCpf = (localStorageEntity, idCnpjCpf) => {
        const localStorageData = localStorageEntity.getLocalStorageData();
        let newLocalStorageData = null;
        newLocalStorageData = _.omit(localStorageData, idCnpjCpf);
        const localStorageKey = localStorageEntity.getKey();
        ls.set(localStorageKey, newLocalStorageData);
    };

    static setPersonExpirationTime = (localStorageEntity, expirationTime = 16) => {
        const currentPersonKey = SessionStorageTabControl.getData(localStorageEntity);
        const localStorageData = localStorageEntity.getLocalStorageData();
        const localStorageKey = localStorageEntity.getKey();
        super.setExpirationTime(localStorageData[currentPersonKey]);
        ls.set(localStorageKey, localStorageData);
    };
}

export default LocalStoragePerson;
