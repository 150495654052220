export default {
    namespace: 'modal',
    state: {
        visible: false,
    },
    reducers: {
        setModalVisible(state, { payload: visible }) {
            return {
                ...state,
                visible,
            };
        },
    },
};
