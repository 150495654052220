import { simulation_getData, simulation_getLimiteData } from '@/services/Request/simulation';
import { decodeTokenAPI } from '@/services/Controllers/Token';
import { errorHandler } from '@/services/Request/error';

export default {
    namespace: 'model_simulation',
    state: {
        requestLoading: false,
        simulationData: [],
        requestError: false,
        simulationRef: null,
        limitResponse: 0,
        setStatusConsultaValorFinal: '1',
        limite: undefined,
        limiteCliente: undefined,
        dadosProposta: null,
        limiteLiquido: null,
        simulacaoValor: null,
        simulacaoValorTotal: null,
        positionPlots: null,
        dadosFinalizacao: null,
    },
    reducers: {
        handlerGetPositionPlots(state, { payload: dados }) {
            return {
                ...state,
                positionPlots: dados.positionPlots,
            };
        },
        handlerGetDadosFinal(state, { payload: dados }) {
            return {
                ...state,
                dadosFinalizacao: dados.dadosFinal,
            };
        },
        handlerGetDadosMaster(state, { payload: dados }) {
            const { personData, datacpf, dadosfinal } = dados;
            const dadosObjetoProposta = {
                idcnpj_cpf_cliente: datacpf?.idcnpj_cpf || '0',
                valortotal: personData?.valortotal || '0',
                valorfinanciado: personData?.valorfinanciado || '0',
                iof: personData?.iof || '0',
                taxa: personData?.taxa || '0',
                coeficiente: personData?.coeficiente || '0',
                irr: personData?.irr || '0',
                cet: personData?.cet || '0',
                cetanual: personData?.cetanual || '0',
                pst: personData?.pst || '0',
                tfc: personData?.tfc || '0',
                tac: personData?.tac || '0',
                conveniada: personData?.conveniada || '0',
                valorsolicitado: dadosfinal?.valorsolicitado || '0',
                valorseguro: dadosfinal?.valorseguro || '0',
                juros: dadosfinal?.juros || '0',
                produto: dadosfinal?.produto || '0',
                gazincred_lojista: dadosfinal?.gazincred_lojista || '0',
                gazincred_loja: dadosfinal?.gazincred_loja || '0',
                plano: dadosfinal?.plano || '0',
                datasimulacao: dadosfinal?.datasimulacao || null,
                solicitarlimite: dadosfinal?.solicitarlimite || false,
                token: dadosfinal?.token || '0',
                parcelas: [
                    ...personData && personData.parcelas ? personData.parcelas : '0'
                ]
            };
            return {
                ...state,
                dadosProposta: dadosObjetoProposta,
            };
        },
        handlerGetContinuarLimite(state, { payload: dados }) {
            const { simulacaoValor, simulacaoValorTotal } = dados;
            const { limitResponse = {} } = state;
            const { limite_liquido = 0 } = limitResponse;
            const shopkeeperHasEnoughLimit = limite_liquido >= simulacaoValorTotal;
            return {
                ...state,
                limite: shopkeeperHasEnoughLimit,
                limiteLiquido: limite_liquido,
                simulacaoValor,
                simulacaoValorTotal,
            };
        },
        setLocationRef(state, { payload: locationRef }) {
            return {
                ...state,
                ...locationRef,
            };
        },
        setRequestStatus(state, { payload: requestError }) {
            return {
                ...state,
                requestError,
            };
        },
        setLimiteValue(state, { payload: limitResponse }) {
            return {
                ...state,
                limitResponse,
            };
        },
        setStatusConsultaValorFinal(state, { payload: setStatusConsultaValorFinal }) {
            return {
                ...state,
                setStatusConsultaValorFinal,
            };
        },
        handlerGetSimulationData(state, { payload: dados }) {
            return {
                ...state,
                simulationData: dados,
            };
        },
        handlerRequestLoading(state, { requestLoading }) {
            return {
                ...state,
                requestLoading,
            };
        },
        handlerRequestError(state, { payload: error }) {
            errorHandler(error);
            return {
                ...state,
            };
        },
    },
    effects: {
        *getSimulationData({ payload: data }, { call, put, select }) {
            yield put({ type: 'spin/setSpinning', payload: true });
            const statusValor = yield select(
                state => state.model_simulation.setStatusConsultaValorFinal,
            );

            try {
                const dadosLoginObject = decodeTokenAPI();
                const { qtdmaxdiaspgt, props, setSimulationData } = data;

                const limitResponse = yield call(simulation_getLimiteData, {
                    idcdclojista: dadosLoginObject.idcdclojista,
                });
                yield put({ type: 'setLimiteValue', payload: limitResponse.data });

                const simulationResponse = yield call(simulation_getData, {
                    empresa: dadosLoginObject.empresa,
                    agencia: dadosLoginObject.agencia,
                    produto: dadosLoginObject.produto,
                    valor: props.valorCompra.value,
                    idtipovalor: parseInt(statusValor, 10),
                    conveniada: dadosLoginObject.conveniada,
                    qtdmaxdiaspgt,
                });

                yield call(setSimulationData, simulationResponse);
            } catch (error) {
                yield put({ type: 'handlerRequestError', payload: error });
                yield put({ type: 'spin/setSpinning', payload: false });
            }
            yield put({ type: 'spin/setSpinning', payload: false });
            yield put({ type: 'handlerRequestLoading', requestLoading: false });
        },
    },
};
