import { lojistas_getData, lojistas_getContractsData } from '@/services/Request/lojistas';
import { statusRejeicao_get } from '@/services/Request/analisarLojista';
import { errorHandler } from '@/services/Request/error';
import { successHandler } from '@/services/Request/success';
import { formatToTable } from '@/services/RetailerAnalysis';

export default {
    namespace: 'model_retailerAnalysis',
    state: {
        requestLoading: false,
        retailerAnalysisData: [],
        requestError: false,
        retailerAnalysisRef: null,
    },
    reducers: {
        setRetailerAnalysisRef(state, { payload: retailerAnalysisRef }) {
            return {
                ...state,
                ...retailerAnalysisRef,
            };
        },
        handlerSetRetailerAnalysisData(state, { payload: dados }) {
            return {
                ...state,
                retailerAnalysisData: dados,
            };
        },
        handlerRequestError(state, { payload: error }) {
            errorHandler(error);
            return {
                ...state,
            };
        },
    },
    effects: {
        *getRetailerAnalysisData(_, { all, call, put, select }) {
            yield put({ type: 'spin/setSpinning', payload: true });
            try {
                const [retailerResponse, contractsResponse, statusRejeicao] = yield all([
                    call(lojistas_getData),
                    call(lojistas_getContractsData),
                    call(statusRejeicao_get),
                ]);
                const retailer = yield call(
                    formatToTable,
                    retailerResponse.data,
                    contractsResponse.data,
                    statusRejeicao.data,
                );
                yield put({ type: 'handlerSetRetailerAnalysisData', payload: retailer });
            } catch (error) {
                yield put({
                    type: 'handlerRequestError',
                    payload: error,
                });
                yield put({ type: 'spin/setSpinning', payload: false });
            }

            yield put({
                type: 'handlerRequestLoading',
                requestLoading: false,
            });
            yield put({ type: 'spin/setSpinning', payload: false });
        },
    },
};
