import { cep_getData } from '@/services/Request/CEP';
import { errorHandler } from '@/services/Request/error';

export default {
    namespace: 'model_membership',
    state: {
        requestLoading: false,
        membershipData: [],
        requestError: false,
        membershipRef: null,
    },
    reducers: {
        setMembershipRef(state, { payload: membershipRef }) {
            return {
                ...state,
                ...membershipRef,
            };
        },
        setRequestStatus(state, { payload: requestError }) {
            return {
                ...state,
                requestError,
            };
        },
        handlerGetMembershipData(state, { payload: dados }) {
            return {
                ...state,
                membershipData: dados,
            };
        },
        handlerRequestLoading(state, { requestLoading }) {
            return {
                ...state,
                requestLoading,
            };
        },
        handlerRequestError(state, { payload: error }) {
            errorHandler(error);
            return {
                ...state,
            };
        },
    },
    effects: {
        *getMembershipData({ payload: data }, { call, put }) {
            yield put({ type: 'spin/setSpinning', payload: true });
            try {
                const {
                    checkAddressUpdated,
                    checkPhoneUpdated,
                    FormMother,
                    FormFather,
                    fatherCep,
                    motherCep,
                } = data;
                if (motherCep) {
                    const motherResponse = yield call(cep_getData, motherCep);
                    FormMother.getPersonData(motherResponse);
                }
                if (fatherCep) {
                    const fatherResponse = yield call(cep_getData, fatherCep);
                    FormFather.getPersonData(fatherResponse);
                }
                yield call(checkAddressUpdated);
                yield call(checkPhoneUpdated);
            } catch (error) {
                yield put({
                    type: 'handlerRequestError',
                    payload: error,
                });
                yield put({ type: 'spin/setSpinning', payload: false });
            }

            yield put({
                type: 'handlerRequestLoading',
                requestLoading: false,
            });
            yield put({ type: 'spin/setSpinning', payload: false });
        },
    },
};
