import { pages } from '@/utils/pages';

const stepProgressStates = {
    SIMULACAO: [0, 0, 0],
    CHECKLIST: [1, 0, 0],
    EPC: [1, 1, 0],
    ARKIVUS: [1, 2, 0],
    DADOS_PRINCIPAIS: [1, 3, 0],
    DADOS_FILIACAO: [1, 3, 1],
    DADOS_LOCALIZACAO: [1, 3, 2],
    DADOS_CONTATO: [1, 3, 3],
    DADOS_REFERENCIA: [1, 3, 4],
    DADOS_TRABALHO: [1, 3, 5],
    DADOS_DEPENDENTE: [1, 3, 6],
    LIMITE_CREDITO: [1, 4, 5],
    LIMITE_CREDITO2: [1, 4, 6],
    FINALIZACAO: [2, 4, 6],
};

const stepProgressStateNames = {
    '000': pages.SIMULACAO,
    100: pages.CHECKLIST,
    110: pages.EPC,
    120: pages.ARKIVUS,
    130: pages.DADOS_PRINCIPAIS,
    131: pages.DADOS_FILIACAO,
    132: pages.DADOS_LOCALIZACAO,
    133: pages.DADOS_CONTATO,
    134: pages.DADOS_REFERENCIA,
    135: pages.DADOS_TRABALHO,
    136: pages.DADOS_DEPENDENTE,
    140: pages.LIMITE_CREDITO,
    145: pages.LIMITE_CREDITO,
    146: pages.LIMITE_CREDITO2,
    246: pages.FINALIZACAO,
    200: pages.FINALIZACAO,
    240: pages.FINALIZACAO,
};

export const getStepProgressPage = path => {
    switch (path) {
        case '/cadastrar/cliente/check-list':
            return [0, 0];
        case '/cadastrar/cliente/protecao-credito':
            return [1, 0];
        default:
            return [0, 0];
    }
};

export const mapPageToStepProgress = page => stepProgressStates[page];

export const mapStepProgressToPage = (current, currentChild, currentGrandson) =>
    stepProgressStateNames[`${current}${currentChild}${currentGrandson}`];
