import SessionStorage from './SessionStorage';

export default class SessionStorageTabControl extends SessionStorage {
    static getTabData = () => super.getSessionStorageData(SESSION_STORAGE_KEYS.TAB_CONTROL);

    static setTabData = data => super.setSessionStorageData(SESSION_STORAGE_KEYS.TAB_CONTROL, data);

    static setData = (localStorageEntity, data) => {
        const sessionStorageKey = localStorageEntity.getKey();
        const sessionStorageData = super.getSessionStorageObjectData(sessionStorageKey) || {};
        sessionStorageData[SESSION_STORAGE_KEYS.TAB_CONTROL] = data;
        super.setSessionStorageObjectData(sessionStorageKey, sessionStorageData);
    };

    static getData = localStorageEntity => {
        const sessionStorageKey = localStorageEntity.getKey();
        const sessionStorageData = super.getSessionStorageObjectData(sessionStorageKey);
        return sessionStorageData ? sessionStorageData[SESSION_STORAGE_KEYS.TAB_CONTROL] : null;
    };
}
