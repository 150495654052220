import {
    urlGetAllRetailer,
    urlSignUpRetailer,
    urlUpdateRetailer,
    urlBaseLimiteLojista,
    urlGetAllRetailerContracts,
    urlValidadeCNPJ_CPF,
    urlGetAllPlans,
    urlGetRetailerCode,
    urlGetAllOccupationTypeShopkeeper,
    urlPostAllOccupationTypeShopkeeper,
    urlDeleteAllOccupationTypeShopkeeper,
} from '@/utils/Requests/url';
import { request } from '@/services/Request/request';
import { axiosRequest } from './get';
import { getToken } from '../Controllers/Token';

export const lojistas_get = async (callbackSuccess, callbackError) => {
    const options = {
        method: 'GET',
        url: urlGetAllRetailer,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    await axiosRequest(options, callbackSuccess, callbackError);
};

const getAllOccupationTypesShopkeeper = async idcdclojista => {
    const options = {
        method: 'GET',
        url: `${urlGetAllOccupationTypeShopkeeper}/${idcdclojista}`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    return request(options);
};

const postOccupationTypesShopkeeper = async types => {
    const options = {
        method: 'POST',
        url: urlPostAllOccupationTypeShopkeeper,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        data: types,
    };
    return request(options);
};

const deleteOccupationTypeShopkeeper = async id => {
    const options = {
        method: 'DELETE',
        url: `${urlDeleteAllOccupationTypeShopkeeper}/${id}`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    return request(options);
};

export const getOccupationTypesShopkeeper = async idcdclojista => {
    const response = await getAllOccupationTypesShopkeeper(idcdclojista);

    return response.data;
};

export const insertOccupationTypesShopkeeper = async (idcdclojista, types) => {
    const data = types.map(idtipoocupacao => ({
        idtipoocupacao: Number(idtipoocupacao),
        idcdclojista,
    }));

    return postOccupationTypesShopkeeper(data);
};

export const updateOccupationTypesShopkeeper = async (idcdclojista, newTypes) => {
    const typesOld = await getOccupationTypesShopkeeper(idcdclojista);

    const insertTypes = newTypes.filter(
        idtipoocupacao =>
            !typesOld.find(typeOld => typeOld.idtipoocupacao.toString() === idtipoocupacao),
    );

    const removedTypes = typesOld.filter(
        typeOld =>
            !newTypes.find(idtipoocupacao => idtipoocupacao === typeOld.idtipoocupacao.toString()),
    );

    Object.values(removedTypes).forEach(type => {
        deleteOccupationTypeShopkeeper(type.id);
    });
    return insertOccupationTypesShopkeeper(idcdclojista, insertTypes);
};

export const lojistas_create = async (data, callbackSuccess, callbackError) => {
    const options = {
        method: 'POST',
        url: urlSignUpRetailer,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        data: {
            ...data,
        },
    };
    await axiosRequest(options, callbackSuccess, callbackError);
};

export const lojistas_getContracts = async (callbackSuccess, callbackError) => {
    const options = {
        method: 'GET',
        url: urlGetAllRetailerContracts,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    await axiosRequest(options, callbackSuccess, callbackError);
};

export const lojistas_update = async (data, callbackSuccess, callbackError) => {
    const { idcdclojista } = data;
    const options = {
        method: 'PUT',
        url: urlUpdateRetailer(idcdclojista),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        data: {
            ...data,
        },
    };
    await axiosRequest(options, callbackSuccess, callbackError);
};

export const lojistas_getData = () => {
    const options = {
        method: 'get',
        url: urlGetAllRetailer,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    return request(options);
};

export const getLojistaByIdCdcLojista = idcdclojista => {
    const options = {
        method: 'get',
        url: `${urlGetAllRetailer}/${idcdclojista}`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    return request(options);
};

// export const lojistas_limit = async (data, callbackSuccess, callbackError) => {
//     const { cnpjcpf } = data;
//     const options = {
//         method: 'GET',
//         url: urlBaseLimiteLojista,
//         headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${getToken()}`,
//         },
//         params: {
//             cnpjcpf,
//         },
//     };
//     await axiosRequest(options, callbackSuccess, callbackError);
// };

export const lojista_getLimit = cnpjcpf => {
    const options = {
        method: 'GET',
        url: urlBaseLimiteLojista,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        params: {
            cnpjcpf,
        },
    };

    return request(options);
};

export const lojistas_getContractsData = () => {
    const options = {
        method: 'GET',
        url: urlGetAllRetailerContracts,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    return request(options);
};

export const checkCNPJ_Retailer = async data => {
    const { cnpj } = data;
    const options = {
        method: 'GET',
        url: urlValidadeCNPJ_CPF(cnpj, 1),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    return request(options);
};

export const checkCNPJ_Branch = async data => {
    const { cnpj } = data;
    const options = {
        method: 'GET',
        url: urlValidadeCNPJ_CPF(cnpj, 2),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    return request(options);
};

export const getPlan = async data => {
    const { cnpj } = data;
    const options = {
        method: 'GET',
        url: urlGetAllPlans(cnpj),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    const requestReturn = await request(options);
    return requestReturn;
};

export const getRetailerCode = async data => {
    const { cnpj } = data;
    const options = {
        method: 'GET',
        url: urlGetRetailerCode(cnpj),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };

    const requestReturn = await request(options);
    return requestReturn;
};
