export default {
    namespace: 'default_step_progress',
    state: {
        canContinue: false,
        canReturn: false,
        hasSimulation: false,
        atualStepProgress: 0,
        status: null,
        componentRef: null,
    },
    reducers: {
        setCanContinue(state, { payload: dados }) {
            return {
                ...state,
                canContinue: dados.canContinue,
            };
        },
        setHasSimulation(state, { payload: hasSimulation }) {
            return {
                ...state,
                hasSimulation,
            };
        },
        setCanReturn(state, { payload: canReturn }) {
            return {
                ...state,
                canReturn,
            };
        },
        setCurrentStepProgress(state, { payload: dados }) {
            return {
                ...state,
                ...dados,
            };
        },
        setComponentRef(state, { payload: dados }) {
            return {
                ...state,
                ...dados,
            };
        },
    },
    effects: {
        *setCurrentPage({ payload }, { put, select }) {
            const state = yield select(s => s.default_step_progress);
            const newCurrentStepProgress =
                typeof payload.atualStepProgress === 'number'
                    ? payload.atualStepProgress
                    : state.atualStepProgress;
            yield put({
                type: 'setCurrentStepProgress',
                payload: {
                    atualStepProgress: newCurrentStepProgress,
                },
            });
        },
        *submit({ payload }, { put, select }) {
            const state = yield select(s => s.default_step_progress);
            const { atualStepProgress } = state;
            const status = state.componentRef.handleSubmit();
            if (status) {
                const newCurrentStepProgress =
                    payload.atualStepProgress
                        ? payload.atualStepProgress
                        : atualStepProgress;
                yield put({
                    type: 'setCurrentStepProgress',
                    payload: {
                        atualStepProgress: newCurrentStepProgress,
                    },
                });
            }
        },
    },
};
