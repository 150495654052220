export default {
    'menu.Minhas Quitações': 'Propostas',

    'proposta.init.error': 'Falha ao inicializar página. Contact o suporte.',
    'proposta.page.title': 'Propostas',

    'proposta.form.fetch-proposals-form.field.date.label': 'Data de proposta',
    'proposta.form.fetch-proposals-form.field.date.message.required':
        'Data de proposta obrigatória',

    'proposta.modal.titulos-modal.title': 'TÍTULOS',

    'proposta.form.fetch-proposals-form.field.status.label': 'Status',
    'proposta.form.fetch-proposals-form.field.status.message.required': 'Status obrigatório',
    'proposta.form.fetch-proposals-form.field.status.message.placeholder': 'Selecione um status',

    'proposta.form.fetch-proposals-form.field.shopkeeper.label': 'Lojista',
    'proposta.form.fetch-proposals-form.field.shopkeeper.message.required': 'Lojista obrigatório',
    'proposta.form.fetch-proposals-form.field.shopkeeper.message.placeholder':
        'Selecione um lojista',

    'proposta.form.fetch-proposals-form.field.customer.label': 'Cliente',
    'proposta.form.fetch-proposals-form.field.customer.message.required': 'Cliente obrigatória',
    'proposta.form.fetch-proposals-form.field.customer.message.placeholder':
        'Digite um nome ou CPF',

    'proposta.form.simulation-form.field.valueType.message.required': 'Tipo de valor obrigatório',

    'proposta.form.simulation-form.field.simulationValue.initialValue.label': 'Valor inicial',
    'proposta.form.simulation-form.field.simulationValue.totalValue.label': 'Valor total',
    'proposta.form.simulation-form.field.simulationValue.installmentValue.label':
        'Valor da parcela',
    'proposta.form.simulation-form.field.simulationValue.message.required': 'Campo obrigatório',

    'proposta.form.simulation-form.field.dueDate.label': 'Vencimento parcela',
    'proposta.form.simulation-form.field.dueDate.message.required':
        'Vencimento parcela obrigatório',
};
