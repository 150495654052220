import { LocalStorage, LocalStoragePerson } from '@/services/LocalStorage';
import { decodeTokenAPI } from '@/services/Controllers/Token';

export default class LocalStoragePersonRegistration extends LocalStorage {
    static getLocalStoragePersonRegistration = () =>
        super.getLocalStorageData(LOCAL_STORAGE_KEYS.PERSON_REGISTRATION);

    static getKey = () => LOCAL_STORAGE_KEYS.PERSON_REGISTRATION;

    static getLocalStorageData = () =>
        super.getLocalStorageData(LOCAL_STORAGE_KEYS.PERSON_REGISTRATION);

    static setLocalStorageData = value => {
        super.setLocalStorageData(LOCAL_STORAGE_KEYS.PERSON_REGISTRATION, value);
    };

    static setLocalStoragePerson = data => {
        const personRegisterData = this.getLocalStorageData() || {};
        const currentPersonKey = LocalStoragePerson.getKey();
        if (data) {
            personRegisterData[currentPersonKey] = data;
        } else {
            personRegisterData[currentPersonKey] = personRegisterData[currentPersonKey] || {};
        }
        super.setExpirationTime(personRegisterData[currentPersonKey]);
        super.setUserId(personRegisterData[currentPersonKey]);
        this.setLocalStorageData(personRegisterData);
    };

    static getInitialStepPage = () => STEP_PAGES.CHECKLIST;

    static getInitialRoute = () => PATH.CADASTRAR_CLIENTE;

    static findRegistersByUser = () => {
        const simulationObject = this.getLocalStorageData();
        const registersByUser = [];
        const { idcdcusuario } = decodeTokenAPI();
        _.mapKeys(simulationObject, register => {
            if (register.siga) {
                if (
                    register.idcdcusuario == idcdcusuario &&
                    register.siga.cnpj_cpf &&
                    register.siga.idcnpj_cpf &&
                    register.siga.nome
                ) {
                    registersByUser.push(register);
                }
            }
        });
        return registersByUser;
    };
}
