import jwt_decode from 'jwt-decode';
import jwt from 'jwt-simple';

/**
 * Pega o token do localStorage
 */
export const getToken = () => localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);

/**
 * Pega o token do localStorage
 */
export const getTokenChange = () => localStorage.getItem(LOCAL_STORAGE_KEYS.TOKENCHANGE);

/**
 * Decodifica o token vindo da API CDC
 */
export const decodeTokenAPI = () => (getToken() ? jwt_decode(getToken()).data : {});

/**
 * Decodifica o token vindo da API CDC
 */
export const decodeTokenAPICHANGE = () => {
    if (getTokenChange()) {
        return jwt_decode(getTokenChange()).data;
    }

    return {};
};

/**
 * Codifica os dados passados por parâmetro
 * @param {*} data
 */
export const encodeToken = data => jwt.encode(data, 'HS256');

/**
 * Decodifica os dados passados por parâmetro
 * @param {*} data
 */
export const decodeToken = data => (data != null ? jwt.decode(data, 'HS256') : undefined);

/**
 * Salva token no local storage
 * @param {*} data
 */
export const setToken = data => localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, data);

/**
 * salva o token do gazin no localStorage
 */
export const setTokenChange = dados => localStorage.setItem(LOCAL_STORAGE_KEYS.TOKENCHANGE, dados);

/**
 * Remove token do local storage
 */
export const removeToken = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
};

/**
 * Remove token do gazin do local storage
 */
export const removeTokenChange = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKENCHANGE);
};
