import {
    analisarlojistaStatus_getData,
    statusRejeicao_get,
} from '@/services/Request/analisarLojista';
import { errorHandler } from '@/services/Request/error';

export default {
    namespace: 'model_AnalisarLojista',
    state: {
        lojistaStatus: [],
        lojistaStatusRejeicao: [],
    },
    reducers: {
        handlerSetStatusData(state, { payload: dados }) {
            return {
                ...state,
                lojistaStatus: dados,
            };
        },
        handlerSetStatusDataRejeicao(state, { payload: dados }) {
            return {
                ...state,
                lojistaStatusRejeicao: dados,
            };
        },
        handlerRequestError(state, { payload: error }) {
            errorHandler(error);
            return {
                ...state,
            };
        },
    },
    effects: {
        *getAnalisarLojistaData(_, { all, call, put }) {
            yield put({ type: 'spin/setSpinning', payload: true });
            try {
                const [statusResponse, statusRejeicaoResponse] = yield all([
                    call(analisarlojistaStatus_getData),
                    call(statusRejeicao_get),
                ]);
                yield put({
                    type: 'handlerSetStatusData',
                    payload: statusResponse.data.filter(
                        data => [5, 4].indexOf(data.idcdcstatuscontratolojista) >= 0,
                    ),
                });
                yield put({
                    type: 'handlerSetStatusDataRejeicao',
                    payload: statusRejeicaoResponse.data,
                });
                yield put({ type: 'spin/setSpinning', payload: false });
            } catch (error) {
                yield put({
                    type: 'handlerRequestError',
                    payload: error,
                });
                yield put({ type: 'spin/setSpinning', payload: false });
            }

            yield put({
                type: 'handlerRequestLoading',
                requestLoading: false,
            });
            yield put({ type: 'spin/setSpinning', payload: false });
        },
    },
};
