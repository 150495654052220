import {
    LocalStoragePerson,
    LocalStoragePersonRegistration,
    LocalStorageSimulation,
} from '@/services/LocalStorage';
import { SessionStorageTabControl } from '@/services/SessionStorage';
import { isCPF } from 'brazilian-values';

export default class LocalStorageFactory {
    static build = (dispatch, hasSimulation) => {
        if (hasSimulation) {
            this.setKeys(dispatch, LocalStorageSimulation);
            LocalStorageSimulation.setLocalStoragePerson(null);
            return LocalStorageSimulation;
        }

        this.setKeys(dispatch, LocalStoragePersonRegistration);
        LocalStoragePersonRegistration.setLocalStoragePerson(null);
        return LocalStoragePersonRegistration;
    };

    static getMainLocalStorage = hasSimulation => {
        if (hasSimulation) {
            LocalStorageSimulation.setLocalStoragePerson(null);
            return LocalStorageSimulation;
        }
        LocalStoragePersonRegistration.setLocalStoragePerson(null);
        return LocalStoragePersonRegistration;
    };

    static setKeys = (dispatch, localStorageEntity) => {
        const sessionStorageKey = SessionStorageTabControl.getData(localStorageEntity)
            ? SessionStorageTabControl.getData(localStorageEntity)
            : '';
        if (!sessionStorageKey || isCPF(sessionStorageKey)) {
            LocalStoragePerson.setInitialKey(dispatch);
            SessionStorageTabControl.setData(localStorageEntity, LocalStoragePerson.getKey());
        } else {
            LocalStoragePerson.setKey(
                dispatch,
                SessionStorageTabControl.getData(localStorageEntity),
            );
        }
    };
}
