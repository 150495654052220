import { request } from '@/services/Request/request';
import { baseSiga } from '@/utils/Requests/url';
import { getToken } from '@/services/Controllers/Token';
/**
 * Class representing the arkivus request service.
 */
export default class ArkivusRequest {
    /**
     * Prepare the request config options
     * @param {String}  cpf cpf to search
     * @return {Object}  Return the request response
     */
    static fetchPhoto = cpf => {
        const options = {
            method: 'get',
            url: `${baseSiga}arkivus/foto/${cpf}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
        };

        return request(options);
    };
}
