export default {
    'registerFiadorForm.modal.modalCep.title': 'Pesquisa de endereço',
    'registerFiadorForm.modal.modalCep.button.selectCity': 'Selecione a cidade',
    'registerFiadorForm.modal.modalCep.button.selectAddress': 'Selecione o endereço',

    'registerFiadorForm.request.fetch.city.error.title': 'Erro ao buscar cidades',
    'registerFiadorForm.request.fetch.address.error.title': 'Erro ao buscar endereços',

    'registerFiadorForm.cepForm.city.label': 'Cidade',
    'registerFiadorForm.cepForm.city.message.required': 'Cidade é obrigatória',
    'registerFiadorForm.cepForm.city.message.placeholder': 'Digite a cidade',

    'registerFiadorForm.notification.error.fiador.register.title': 'Erro ao inserir fiador',
    'registerFiadorForm.notification.error.fiador.register.registered.message':
        'Fiador já registrado',

    'registerFiadorForm.button.save.title': 'Salvar',
    'registerFiadorForm.button.insert.title': 'Inserir',
    'registerFiadorForm.button.next.title': 'Próximo',
    'registerFiadorForm.button.clearForm.title': 'Limpar formulário',
    'registerFiadorForm.button.previous.title': 'Voltar',

    'registerFiadorForm.tab.fiadorForm.title': 'Fiador',
    'registerFiadorForm.tab.fiadorConjugeForm.title': 'Cônjuge',

    'registerFiadorForm.form.fiadorName.label': 'Nome',
    'registerFiadorForm.form.fiadorName.message.required': 'Nome é obrigatório.',
    'registerFiadorForm.form.fiadorName.message.placeholder': 'Digite o nome',

    'registerFiadorForm.form.fiadorNationality.label': 'Naturalidade',
    'registerFiadorForm.form.fiadorNationality.message.required': 'Naturalidade é obrigatória.',
    'registerFiadorForm.form.fiadorNationality.message.placeholder': 'Digite a naturalidade',

    'registerFiadorForm.form.fiadorMaritalStatus.label': 'Estado civil',
    'registerFiadorForm.form.fiadorMaritalStatus.message.required': 'Estado civil é obrigatório.',
    'registerFiadorForm.form.fiadorMaritalStatus.message.placeholder': 'Selecione o Estado civil',

    'registerFiadorForm.form.fiadorOccupation.label': 'Ocupação',
    'registerFiadorForm.form.fiadorOccupation.message.required': 'Ocupação é obrigatório.',
    'registerFiadorForm.form.fiadorOccupation.message.placeholder': 'Selecione o ocupação',

    'registerFiadorForm.form.fiadorRg.label': 'RG',
    'registerFiadorForm.form.fiadorRg.message.required': 'RG é obrigatório.',
    'registerFiadorForm.form.fiadorRg.message.placeholder': 'Digite o RG',

    'registerFiadorForm.form.fiadorCnpj_cpf.label': 'CPF',
    'registerFiadorForm.form.fiadorCnpj_cpf.message.required': 'CPF é obrigatório.',
    'registerFiadorForm.form.fiadorCnpj_cpf.message.placeholder': 'Digite o CPF',
    'registerFiadorForm.form.fiadorCnpj_cpf.message.notvalid': 'CPF inválido',

    'registerFiadorForm.form.fiadorRgDispatchingDate.label': 'Data de expedição',
    'registerFiadorForm.form.fiadorRgDispatchingDate.message.required':
        'Data de expedição é obrigatória.',
    'registerFiadorForm.form.fiadorRgDispatchingDate.message.placeholder':
        'Selecione o data de expedição',

    'registerFiadorForm.form.fiadorRgDispatchingAgency.label': 'Orgão emissor',
    'registerFiadorForm.form.fiadorRgDispatchingAgency.message.required':
        'Orgão emissor é obrigatório.',
    'registerFiadorForm.form.fiadorRgDispatchingAgency.message.placeholder': 'Ex: SSP PR',

    'registerFiadorForm.form.fiadorCep.label': 'CEP',
    'registerFiadorForm.form.fiadorCep.message.required': 'CEP é obrigatório.',
    'registerFiadorForm.form.fiadorCep.message.placeholder': 'Digite o CEP',

    'registerFiadorForm.form.fiadorAddress.label': 'Endereço',
    'registerFiadorForm.form.fiadorAddress.message.required': 'Endereço é obrigatório.',
    'registerFiadorForm.form.fiadorAddress.message.placeholder': 'Digite o endereço',

    'registerFiadorForm.form.fiadorAddressNumber.label': 'Número',
    'registerFiadorForm.form.fiadorAddressNumber.message.required': 'Número é obrigatório.',
    'registerFiadorForm.form.fiadorAddressNumber.message.placeholder': 'Digite o número',

    'registerFiadorForm.form.fiadorNeighborhood.label': 'Bairro',
    'registerFiadorForm.form.fiadorNeighborhood.message.required': 'Bairro é obrigatório.',
    'registerFiadorForm.form.fiadorNeighborhood.message.placeholder': 'Digite o bairro',

    'registerFiadorForm.form.fiadorCity.label': 'Cidade',
    'registerFiadorForm.form.fiadorCity.message.required': 'Cidade é obrigatório.',
    'registerFiadorForm.form.fiadorCity.message.placeholder': 'Digite o cidade',

    'registerFiadorForm.form.fiadorUf.label': 'Estado',
    'registerFiadorForm.form.fiadorUf.message.required': 'Estado é obrigatório.',
    'registerFiadorForm.form.fiadorUf.message.placeholder': 'Digite o estado',

    'registerFiadorForm.form.conjugeName.label': 'Nome',
    'registerFiadorForm.form.conjugeName.message.required': 'Nome é obrigatório.',
    'registerFiadorForm.form.conjugeName.message.placeholder': 'Digite o nome',

    'registerFiadorForm.form.conjugeNationality.label': 'Naturalidade',
    'registerFiadorForm.form.conjugeNationality.message.required': 'Naturalidade é obrigatória.',
    'registerFiadorForm.form.conjugeNationality.message.placeholder': 'Digite a naturalidade',

    'registerFiadorForm.form.conjugeMaritalStatus.label': 'Estado civil',
    'registerFiadorForm.form.conjugeMaritalStatus.message.required': 'Estado civil é obrigatório.',
    'registerFiadorForm.form.conjugeMaritalStatus.message.placeholder': 'Selecione o Estado civil',

    'registerFiadorForm.form.conjugeOccupation.label': 'Ocupação',
    'registerFiadorForm.form.conjugeOccupation.message.required': 'Ocupação é obrigatório.',
    'registerFiadorForm.form.conjugeOccupation.message.placeholder': 'Selecione o ocupação',

    'registerFiadorForm.form.conjugeRg.label': 'RG',
    'registerFiadorForm.form.conjugeRg.message.required': 'RG é obrigatório.',
    'registerFiadorForm.form.conjugeRg.message.placeholder': 'Digite o RG',

    'registerFiadorForm.form.conjugeCnpj_cpf.label': 'CPF',
    'registerFiadorForm.form.conjugeCnpj_cpf.message.required': 'CPF é obrigatório.',
    'registerFiadorForm.form.conjugeCnpj_cpf.message.placeholder': 'Digite o CPF',
    'registerFiadorForm.form.conjugeCnpj_cpf.message.notvalid': 'CPF inválido',

    'registerFiadorForm.form.conjugeRgDispatchingDate.label': 'Data de expedição',
    'registerFiadorForm.form.conjugeRgDispatchingDate.message.required':
        'Data de expedição é obrigatória.',
    'registerFiadorForm.form.conjugeRgDispatchingDate.message.placeholder':
        'Selecione o data de expedição',

    'registerFiadorForm.form.conjugeRgDispatchingAgency.label': 'Orgão emissor',
    'registerFiadorForm.form.conjugeRgDispatchingAgency.message.required':
        'Orgão emissor é obrigatório.',
    'registerFiadorForm.form.conjugeRgDispatchingAgency.message.placeholder': 'Ex: SSP PR',

    'registerFiadorForm.form.conjugeCep.label': 'CEP',
    'registerFiadorForm.form.conjugeCep.message.required': 'CEP é obrigatório.',
    'registerFiadorForm.form.conjugeCep.message.placeholder': 'Digite o CEP',

    'registerFiadorForm.form.conjugeAddress.label': 'Endereço',
    'registerFiadorForm.form.conjugeAddress.message.required': 'Endereço é obrigatório.',
    'registerFiadorForm.form.conjugeAddress.message.placeholder': 'Digite o endereço',

    'registerFiadorForm.form.conjugeAddressNumber.label': 'Número',
    'registerFiadorForm.form.conjugeAddressNumber.message.required': 'Número é obrigatório.',
    'registerFiadorForm.form.conjugeAddressNumber.message.placeholder': 'Digite o número',

    'registerFiadorForm.form.conjugeNeighborhood.label': 'Bairro',
    'registerFiadorForm.form.conjugeNeighborhood.message.required': 'Bairro é obrigatório.',
    'registerFiadorForm.form.conjugeNeighborhood.message.placeholder': 'Digite o bairro',

    'registerFiadorForm.form.conjugeCity.label': 'Cidade',
    'registerFiadorForm.form.conjugeCity.message.required': 'Cidade é obrigatório.',
    'registerFiadorForm.form.conjugeCity.message.placeholder': 'Digite o cidade',

    'registerFiadorForm.form.conjugeUf.label': 'Estado',
    'registerFiadorForm.form.conjugeUf.message.required': 'Estado é obrigatório.',
    'registerFiadorForm.form.conjugeUf.message.placeholder': 'Digite o estado',
};
