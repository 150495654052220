import { getAuxiliaryData } from '@/services/Request/auxiliaries';
import { LocalStorageAuxiliaries } from '@/services/LocalStorage';
import { Notification } from '@/components/Modal/Notification';

export default {
    namespace: 'model_auxiliaries',
    state: {
        auxiliaryData: {},
    },
    reducers: {
        setAuxiliaryData(state, { payload: auxiliaryData }) {
            return {
                ...state,
                auxiliaryData,
            };
        },
    },
    effects: {
        *setAuxiliaries(_, { call, put }) {
            yield put({ type: 'spin/setSpinning', payload: true });

            try {
                const response = yield call(getAuxiliaryData);
                yield put({ type: 'setAuxiliaryData', payload: response.data });
                yield call(LocalStorageAuxiliaries.setData, response.data);
            } catch (error) {
                Notification(
                    PROJECT_MESSAGE.ERROR_TYPE_MESSAGE,
                    PROJECT_MESSAGE.ERROR_TITLE_AUX,
                    PROJECT_MESSAGE.ERROR_MSG_CONTACT_SUPPORT,
                );
            }

            yield put({ type: 'spin/setSpinning', payload: false });
        },
    },
};
