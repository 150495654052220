import { SessionStorageTabControl } from '@/services/SessionStorage';
import localStorage from 'local-storage';
import omit from 'lodash/omit';

export default class LocalStorageSimulationData {
    static getKey = () => LOCAL_STORAGE_KEYS.SIMULATION_DATA;

    static getData = localStorageEntity => {
        const currentPersonKey = SessionStorageTabControl.getData(localStorageEntity);
        const localStorageData = localStorageEntity.getLocalStorageData();
        return localStorageData[currentPersonKey][LOCAL_STORAGE_KEYS.SIMULATION_DATA];
    };

    static setData = (localStorageEntity, value) => {
        const currentPersonKey = SessionStorageTabControl.getData(localStorageEntity);
        const localStorageData = localStorageEntity.getLocalStorageData();
        const localStorageKey = localStorageEntity.getKey();
        localStorageData[currentPersonKey][LOCAL_STORAGE_KEYS.SIMULATION_DATA] = value;
        localStorage.set(localStorageKey, localStorageData);
    };

    static removeData = localStorageEntity => {
        const currentPersonKey = SessionStorageTabControl.getData(localStorageEntity);
        const localStorageData = localStorageEntity.getLocalStorageData();
        const newLocalStorageData = localStorageData;
        // if(localStorageData[currentPersonKey])
        newLocalStorageData[currentPersonKey] = omit(
            localStorageData[currentPersonKey],
            LOCAL_STORAGE_KEYS.SIMULATION_DATA,
        );
        const localStorageKey = localStorageEntity.getKey();
        localStorage.set(localStorageKey, newLocalStorageData);
    };
}
