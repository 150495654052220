import { decodeTokenAPI } from '@/services/Controllers/Token';
import omitBy from 'lodash/omitBy';

const localStorage = require('local-storage');

export default class LocalStorage {
    constructor(key) {}

    static setKey = key => {};

    static getKey = () => {};

    static setLocalStorageData = (key, value) => {
        localStorage.set(key, value);
    };

    static getLocalStorageData = key => localStorage.get(key);

    static expiry = localStorageEntity => {
        const now = new Date().getTime();
        const localStorageData = localStorageEntity.getLocalStorageData();
        const newLocalStorageData = omitBy(
            localStorageData,
            data => now - data.setupTime > data.expirationTime * 60 * 60 * 1000,
        );
        const localStorageKey = localStorageEntity.getKey();
        this.setLocalStorageData(localStorageKey, newLocalStorageData);
    };

    static setExpirationTime = (localStorageData, expirationTime = 16) => {
        if (!localStorageData.expirationTime) {
            const now = new Date().getTime();
            localStorageData.expirationTime = expirationTime;
            // localStorageData.expirationTime = 1 / 180;
            // localStorageData.expirationTime = 1 / 120;
            localStorageData.setupTime = now;
        }
    };

    static setUserId = localStorageData => {
        if (!localStorageData.idcdcusuario) {
            const { idcdcusuario } = decodeTokenAPI();
            localStorageData.idcdcusuario = idcdcusuario;
        }
    };
}
