import router from 'umi/router';
import jwt_decode from 'jwt-decode';
import { Notification } from '@/components/Modal/Notification';
import { getInitialRoutePath } from '@/pages/Route/services';
import { getToken, removeToken } from './Token';

/**
 * Verifica se o token de acesso do usuário não está expirado
 * @param {*} token
 */
export const checkExpiredToken = token => {
    if (token) {
        const decodedToken = jwt_decode(token);

        if (decodedToken != null) {
            if (decodedToken.exp < new Date().getTime() / 1000) {
                Notification(
                    'info',
                    'Sessão expirada',
                    'Sua sessão expirou, realize o login novamente!',
                );
                return true;
            }
        }
    }
    return false;
};

/**
 * Verifica se há token no local storage
 */
export const hasToken = () => {
    const token = getToken();
    if (token) {
        return true;
    }
    return false;
};

/**
 * Verifica se é a rota raiz ('/')
 * @param {*} path
 */
export const isRootPage = path => path === '/';

/**
 * Realiza o logout do sistema
 */
export const logout = () => {
    router.push(PATH.LOGIN);
    sessionStorage.clear();
    removeToken();
};

/**
 * Controla o redirecionamento das páginas no login
 * @param {*} path
 */
export const controlPage = path => {
    const token = getToken();
    if (!token) {
        router.push(PATH.LOGIN);
        return;
    }

    if (checkExpiredToken(token)) {
        logout();
    }

    if (isRootPage(path)) {
        router.push(getInitialRoutePath());
    }
};

/**
 * Limpa os dados do sessionStorage e localStorage
 */

export const clearDataStorage = () => {
    const simulation = localStorage.getItem('simulation');
    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('simulation', simulation);
};
