import _ from 'lodash';
import { decodeTokenAPI } from '../Controllers/Token';

export const isShopkeeper = () =>
    decodeTokenAPI().idcdclojista && decodeTokenAPI().idcdclojistafiliais;

export const getIdCdcLojista = () => decodeTokenAPI().idcdclojista;

export const getEmpresa = () => decodeTokenAPI().empresa;

export const getAgencia = () => decodeTokenAPI().agencia;

export const getProduto = () => decodeTokenAPI().produto;

export const getConveniada = () => decodeTokenAPI().conveniada;

export const getActions = () => decodeTokenAPI().acoes || [];

export const hasActions = () => getActions().length > 0;

export const isMaster = () => {
    const actions = getActions();
    const masterFound = _.find(actions, action => action.idcdcacoes == ID_ACOES.LOJISTA_MASTER);
    return !!masterFound;
};

export const canChangeLogin = () => {
    const actions = getActions();
    const found = _.find(actions, action => action.idcdcacoes == ID_ACOES.TROCA_LOGIN);
    return !!found;
};

export const canRegisterActions = () => {
    const actions = getActions();
    const found = _.find(actions, action => action.idcdcacoes == ID_ACOES.CADASTRO_ACOES);
    return !!(found && hasActions());
};

export const getActionKeys = actions => {
    const keys = _.map(actions, 'key');
    return keys;
};

export const sortActionsByKeys = (actions, keys) => {
    const sortedArray = [];
    _.map(keys, key => {
        sortedArray.push(_.find(actions, action => action.key == key));
    });
    return sortedArray;
};

export const getActionsByKeys = (actions, keys) => {
    const actionsFound = _.intersectionWith(actions, keys, (action, key) => action.key == key);
    return actionsFound;
};

export const getActionIds = actions => {
    const keys = _.map(actions, 'idcdcacoes');
    return keys;
};
