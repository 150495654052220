import fenixInstance from '..';

/**
 * Método utilizado para a criação de um processo
 * @param {string} cpf
 * @returns {Promise.<any>}
 */
export const getRiskAnalysisByCpf = async cpf => {
    try {
        const { data } = await fenixInstance.get(`/risk-analysis/by-cpf/${cpf}`);
        return data;
    } catch (e) {
        const { data } = e?.response || {};

        return {
            status: 9999,
            code: data?.code,
            message: data?.message,
        };
    }
};
