import axios from 'axios';
import * as Sentry from '@sentry/react';

export const get = async (url, options = {}, callbackSucces, callbackError) => {
    axios
        .get(url, {
            ...options,
        })
        .then(response => {
            callbackSucces(response);
        })
        .catch(error => {
            if (error.response != undefined) {
                callbackError(error.response);
            } else if (error.request) {
                Sentry.captureException(error);
                error.request.data = {};
                error.request.data.message = 'Requisição inválida!';
                callbackError(error.request);
            } else {
                Sentry.captureException(error);
                callbackError(error);
            }
        })
        .finally(() => {});
};

export const axiosRequest = (options = {}, callbackSucces, callbackError) => {
    request(options)
        .then(response => {
            callbackSucces(response);
        })
        .catch(error => {
            let errorMessage = '';

            if (error.response != undefined) {
                if (error.response.data) {
                    if (error.response.data[0]) {
                        if (error.response.data[0].length > 0) {
                            errorMessage = error.response.data[0].message;
                        } else {
                            errorMessage = error.response.statusText;
                        }
                    } else if (error.response.data) {
                        errorMessage = error.response.data;
                    } else {
                        errorMessage = error.response.statusText;
                    }
                } else {
                    errorMessage = error.response.statusText;
                }
                callbackError(errorMessage);
            } else if (error.request) {
                Sentry.captureException(error);
                error.request.data = {};
                errorMessage = 'Requisição inválida!';
                callbackError(errorMessage);
            } else {
                Sentry.captureException(error);
                callbackError(error);
            }
        })
        .finally(() => {});
};

export const axiosRequestDashboard = (options = {}, callbackSucces, callbackError) => {
    request(options)
        .then(response => {
            callbackSucces(response);
        })
        .catch(error => {
            let errorMessage = '';
            let errorStatus = '';
            if (error.response != undefined) {
                if (error.response.data) {
                    if (error.response.data[0]) {
                        if (error.response.data[0].length > 0) {
                            errorMessage = error.response.data[0].message;
                            errorStatus = error.response.status;
                        } else {
                            errorMessage = error.response.statusText;
                            errorStatus = error.response.status;
                        }
                    } else if (error.response.data) {
                        errorMessage = error.response.data;
                        errorStatus = error.response.status;
                    } else {
                        errorMessage = error.response.statusText;
                        errorStatus = error.response.status;
                    }
                } else {
                    errorMessage = error.response.statusText;
                    errorStatus = error.response.status;
                }
                callbackError({ errorMessage, errorStatus });
            } else if (error.request) {
                Sentry.captureException(error);
                error.request.data = {};
                errorMessage = 'Requisição inválida!';
                errorStatus = error.response ? error.response.status : 500;
                callbackError({ errorMessage, errorStatus });
            } else {
                Sentry.captureException(error);
                callbackError({ error, errorStatus });
            }
        })
        .finally(() => {});
};

export const axiosRequestProposta = (options = {}, callbackSucces, callbackError) => {
    request(options)
        .then(response => {
            callbackSucces(response);
        })
        .catch(error => {
            let errorMessage = '';
            if (error.response != undefined) {
                Sentry.captureException(error);
                errorMessage = error.response;
                callbackError(errorMessage);
            }
        })
        .finally(() => {});
};

const request = async options => {
    const response = await axios({
        ...options,
    });

    return response;
};
