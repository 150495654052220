import LocalStorage from './LocalStorage';

export default class LocalStorageCurrentPage extends LocalStorage {
    constructor() {
        super('current-page');
    }

    static getDataCurrentPage = () => {
        super.setKey('current-page');
        return super.getLocalStorageData();
    };

    static setDataCurrentPage = value => {
        super.setLocalStorageData(value);
    };

    static getCanContinue = () => this.getDataCurrentPage().canContinue;

    static setCanContinue = data => {
        const currentPage = this.getDataCurrentPage();
        currentPage.canContinue = data;
        this.setDataCurrentPage(currentPage);
    };

    static getPage = () => this.getDataCurrentPage().page;

    static setPage = data => {
        const currentPage = this.getDataCurrentPage();
        currentPage.page = data;
        this.setDataCurrentPage(currentPage);
    };

    static getRedirect = () => this.getDataCurrentPage().redirect;

    static setRedirect = data => {
        const currentPage = this.getDataCurrentPage();
        currentPage.redirect = data;
        this.setDataCurrentPage(currentPage);
    };

    static getShowEndButton = () => this.getDataCurrentPage().showEndButton;

    static setShowEndButton = data => {
        const currentPage = this.getDataCurrentPage();
        currentPage.showEndButton = data;
        this.setDataCurrentPage(currentPage);
    };

    static getShowNextButtom = () => this.getDataCurrentPage().showNextButtom;

    static setShowNextButtom = data => {
        const currentPage = this.getDataCurrentPage();
        currentPage.showNextButtom = data;
        this.setDataCurrentPage(currentPage);
    };

    static getShowPreviousButtom = () => this.getDataCurrentPage().showPreviousButtom;

    static setShowPreviousButtom = data => {
        const currentPage = this.getDataCurrentPage();
        currentPage.showPreviousButtom = data;
        this.setDataCurrentPage(currentPage);
    };
}
