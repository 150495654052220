import { SessionStorageTabControl } from '@/services/SessionStorage';

const localStorage = require('local-storage');

export default class LocalStorageStepTab {
    static getKey = () => LOCAL_STORAGE_KEYS.STEP_TAB;

    static getLocalStorageStepTab = localStorageEntity => {
        const currentPersonKey = SessionStorageTabControl.getData(localStorageEntity);
        // let simulationData = LocalStorageFactory.getMainLocalStorage(hasSimulation).getLocalStorageData();
        const localStorageData = localStorageEntity.getLocalStorageData();
        return localStorageData[currentPersonKey][LOCAL_STORAGE_KEYS.STEP_TAB];
    };

    static setLocalStorageStepTab = (localStorageEntity, value) => {
        const currentPersonKey = SessionStorageTabControl.getData(localStorageEntity);
        let localStorageData = localStorageEntity.getLocalStorageData();
        const localStorageKey = localStorageEntity.getKey();

        if (!localStorageData) {
            localStorageData = {};
        }
        if (!localStorageData[currentPersonKey]) {
            localStorageData[currentPersonKey] = {};
        }
        localStorageData[currentPersonKey][LOCAL_STORAGE_KEYS.STEP_TAB] = value;
        localStorage.set(localStorageKey, localStorageData);
    };
}
