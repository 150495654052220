import {
    lojista_getLimit,
    getPlan,
    getLojistaByIdCdcLojista,
    getRetailerCode,
} from '@/services/Request/lojistas';
import { errorHandler } from '@/services/Request/error';
import { all } from 'redux-saga/effects';

export default {
    namespace: 'model_retailer',
    state: {
        requestLoading: false,
        RetailerData: [],
        requestError: false,
        retailerCode: null,
        branchGazinCred: null,
        retailerPlans: [],
        currentShopkeeperData: [],
    },
    reducers: {
        setDefaultValues(state) {
            return {
                ...state,
                retailerCode: null,
                retailerPlans: [],
            };
        },
        setCurrentShopkeeper(state, { payload: data }) {
            return {
                ...state,
                currentShopkeeperData: data,
            };
        },
        setDefaultBranchValues(state) {
            return {
                ...state,
                branchGazinCred: null,
            };
        },
        setRequestStatus(state, { payload: requestError }) {
            return {
                ...state,
                requestError,
            };
        },
        handlerGetRetailerData(state, { payload: dados }) {
            return {
                ...state,
                retailerData: dados,
            };
        },
        handlerRequestLoading(state, { requestLoading }) {
            return {
                ...state,
                requestLoading,
            };
        },
        handlerRequestError(state, { payload: error }) {
            errorHandler(error);
            return {
                ...state,
            };
        },
        getCNPJ_Status(state, { payload: status }) {
            return {
                ...state,
                CNPJ_Status: status,
            };
        },
        getRetailerCodeData(state, { payload: code }) {
            return {
                ...state,
                retailerCode: code,
            };
        },
        getPlanData(state, { payload: plans }) {
            return {
                ...state,
                retailerPlans: plans,
            };
        },
        getBranchGazinCred(state, { payload: gazinCred }) {
            return {
                ...state,
                branchGazinCred: gazinCred,
            };
        },
    },
    effects: {
        *retailerUpdate({ payload: data }, { call, put }) {
            yield put({ type: 'spin/setSpinning', payload: true });
            try {
                const { cnpj, handleRetailerUpdate } = data;
                const response = yield call(lojista_getLimit, cnpj);
                const retailerLimit = response.data.limite_base;

                yield call(handleRetailerUpdate, retailerLimit);
                // yield call(checkPhoneUpdated)
                yield put({ type: 'spin/setSpinning', payload: false });
            } catch (error) {
                yield put({
                    type: 'handlerRequestError',
                    payload: error,
                });
                yield put({ type: 'spin/setSpinning', payload: false });
            }

            yield put({
                type: 'handlerRequestLoading',
                requestLoading: false,
            });
        },
        *getPlans(_, { call, put }) {
            yield put({ type: 'spin/setSpinning', payload: true });
            try {
                const { cnpj } = _.payload;
                const [data_RetailerCode, data_plans] = yield all([
                    call(getRetailerCode, {
                        cnpj,
                    }),
                    call(getPlan, {
                        cnpj,
                    }),
                ]);
                yield put({ type: 'getRetailerCodeData', payload: data_RetailerCode.data });
                yield put({ type: 'getPlanData', payload: data_plans.data });
                yield put({ type: 'spin/setSpinning', payload: false });
            } catch (error) {
                yield put({
                    type: 'handlerRequestError',
                    payload: error,
                });
                yield put({ type: 'spin/setSpinning', payload: false });
            }
            yield put({
                type: 'handlerRequestLoading',
                requestLoading: false,
            });
        },
        *getBranchGazincredData(_, { call, put }) {
            yield put({ type: 'spin/setSpinning', payload: true });
            try {
                const { cnpj } = _.payload;
                const branchGazinCredData = yield call(getRetailerCode, { cnpj });
                yield put({ type: 'getBranchGazinCred', payload: branchGazinCredData.data });
                yield put({ type: 'spin/setSpinning', payload: false });
            } catch (error) {
                yield put({
                    type: 'handlerRequestError',
                    payload: error,
                });
                yield put({ type: 'spin/setSpinning', payload: false });
            }
            yield put({
                type: 'handlerRequestLoading',
                requestLoading: false,
            });
        },
        *getCurrentShopkeeper(_, { call, put }) {
            yield put({ type: 'spin/setSpinning', payload: true });
            try {
                const { idcdclojista } = _.payload;
                const response = yield call(getLojistaByIdCdcLojista, idcdclojista);
                yield put({ type: 'setCurrentShopkeeper', payload: response.data });
            } catch (error) {
                yield put({
                    type: 'handlerRequestError',
                    payload: error,
                });
            }

            yield put({ type: 'spin/setSpinning', payload: false });
        },
    },
};
