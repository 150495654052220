import { LocalStorage } from '@/services/LocalStorage';
import { SessionStorageTabControl } from '@/services/SessionStorage';
import omit from 'lodash/omit';

const localStorage = require('local-storage');

export default class LocalStorageFinishingData extends LocalStorage {
    constructor() {
        super(LOCAL_STORAGE_KEYS.FINISHING_DATA);
    }

    static getKey = () => LOCAL_STORAGE_KEYS.FINISHING_DATA;

    static getData = localStorageEntity => {
        const currentPersonKey = SessionStorageTabControl.getData(localStorageEntity);
        const localStorageData = localStorageEntity.getLocalStorageData();

        return localStorageData[currentPersonKey][LOCAL_STORAGE_KEYS.FINISHING_DATA];
    };

    static setData = (localStorageEntity, value) => {
        const currentPersonKey = SessionStorageTabControl.getData(localStorageEntity);
        const localStorageData = localStorageEntity.getLocalStorageData();
        const localStorageKey = localStorageEntity.getKey();
        localStorageData[currentPersonKey][LOCAL_STORAGE_KEYS.FINISHING_DATA] = value;
        localStorage.set(localStorageKey, localStorageData);
    };

    static removeData = localStorageEntity => {
        const currentPersonKey = SessionStorageTabControl.getData(localStorageEntity);
        const localStorageData = localStorageEntity.getLocalStorageData();
        const newLocalStorageData = localStorageData;

        newLocalStorageData[currentPersonKey] = omit(
            localStorageData[currentPersonKey],
            LOCAL_STORAGE_KEYS.FINISHING_DATA,
        );
        const localStorageKey = localStorageEntity.getKey();
        localStorage.set(localStorageKey, newLocalStorageData);
    };
}
