import {
    dash_board_lojista_limite,
    dash_board_clientes_diaData,
    ConsultaPropostasData,
    ConsultaPropostasValor,
    ConsultaPropostasCardData,
    ConsultaPropostasPieData,
    getProposal,
} from '@/services/Request/dashboard';
import { errorHandler } from '@/services/Request/error';
import { all } from 'redux-saga/effects';
import { Console } from '@/utils/Console';
import moment from 'moment';
import { formatToCPF } from 'brazilian-values';
import sortBy from 'lodash/sortBy';

const nowDay = moment([]);

export default {
    namespace: 'model_DashBoard',
    state: {
        dataProposta: [],
        dataClientes: [],
        dataDashBoard: null,
        clientValue: '0',
        dataF: [],
        BasiccolumnData: [],
        arrayData: [],
        canceladoValueUnit: '',
        aguardandoValueUnit: '',
        concluidoValueUnit: '',
        pendenteValueUnit: '',
        concluidoValue: '',
        aguardandoValue: '',
        pendenteValue: '',
        canceladoValue: '',
        lojistaData: [],
        arrayDataPie: [],
        limitLojista: null,
        proposal: [],
        arrayProposalValuesSorted: [],
    },
    reducers: {
        handlerInitDashboard(state) {
            return {
                ...state,
                dataProposta: [],
                dataClientes: [],
                dataDashBoard: null,
                clientValue: '0',
                dataF: [],
                BasiccolumnData: [],
                arrayData: [],
                canceladoValueUnit: '',
                aguardandoValueUnit: '',
                concluidoValueUnit: '',
                pendenteValueUnit: '',
                concluidoValue: '',
                aguardandoValue: '',
                pendenteValue: '',
                canceladoValue: '',
                lojistaData: [],
                arrayDataPie: [],
                arrayPropostasValor: [],
                proposal: [],
                arrayProposalValuesSorted: [],
            };
        },
        handlerSetRetailerDataProposta(state, { payload: dados }) {
            return {
                ...state,
                dataProposta: dados,
                clientValue: dados.data.quantidade,
            };
        },
        handlerSetRetailerDataClient(state, { payload: dados }) {
            return {
                ...state,
                dataF: sortBy(dados.data, ['dataproposta']),
            };
        },
        handlerSetRetailerDataClientMapping(state, { payload: dados }) {
            return {
                ...state,
                BasiccolumnData: dados,
            };
        },
        handlerSetProposal(state, { payload: dados }) {
            return {
                ...state,
                proposal: dados.data,
            };
        },
        handlerSetRetailerDataPropostasPie(state, { payload: dados }) {
            return {
                ...state,
                arrayDataPie: dados,
            };
        },
        handlerSetRetailerDataPropostasCard(state, { payload: dados }) {
            const {
                canceladoValueUnit,
                aguardandoValueUnit,
                concluidoValueUnit,
                pendenteValueUnit,
                concluidoValue,
                aguardandoValue,
                canceladoValue,
                pendenteValue,
            } = dados[0];
            return {
                ...state,
                concluidoValueUnit,
                aguardandoValueUnit,
                pendenteValueUnit,
                canceladoValueUnit,
                concluidoValue,
                aguardandoValue,
                canceladoValue,
                pendenteValue,
            };
        },
        handlerSetRetailerDataPropostasValor(state, { payload: dados }) {
            return {
                ...state,
                arrayPropostasValor: dados,
            };
        },
        setRetailerDataProposalValuesSorted(state, { payload: dados }) {
            return {
                ...state,
                arrayProposalValuesSorted: dados,
            };
        },
        handlerSetRetailerLimitLojista(state, { payload: dados }) {
            return {
                ...state,
                limitLojista: dados.data,
            };
        },
        handlerRequestError(state, { payload: error }) {
            errorHandler(error);
            return {
                ...state,
            };
        },
    },
    effects: {
        *getDashBoardData(_, { call, put }) {
            yield put({ type: 'spin/setSpinning', payload: true });
            try {
                const { firstday, lastday, idcdclojista, idcdclojistafiliais } = _.payload;
                const [
                    clienteMes,
                    propostasData,
                    propostasCardData,
                    propostasPieData,
                    propostasDataValor,
                ] = yield all([
                    call(dash_board_clientes_diaData, {
                        firstday,
                        lastday,
                        idcdclojista,
                        idcdclojistafiliais,
                    }),
                    call(ConsultaPropostasData, {
                        firstday,
                        lastday,
                        idcdclojista,
                        idcdclojistafiliais,
                    }),
                    call(ConsultaPropostasCardData, {
                        firstday,
                        lastday,
                        idcdclojista,
                        idcdclojistafiliais,
                    }),
                    call(ConsultaPropostasPieData, {
                        firstday,
                        lastday,
                        idcdclojista,
                        idcdclojistafiliais,
                    }),
                    call(ConsultaPropostasValor, {
                        firstday,
                        lastday,
                        idcdclojista,
                        idcdclojistafiliais,
                    }),
                ]);
                const dataSource = sortBy(propostasData.data, ['dataproposta']);
                const arrayDataSource = [];
                const arrayDataCard = [];
                const arrayDataPie = [];
                if (dataSource) {
                    dataSource.forEach(elem => {
                        elem.forEach(data => {
                            arrayDataSource.push({
                                dataproposta: data.dataproposta,
                                descricaostatus: data.descricaostatus,
                                idstatus: data.idstatus,
                                quantidade: data.quantidade,
                            });
                        });
                    });
                } else {
                    return;
                }
                const nowDayFormat = nowDay.format('DD/MM/YYYY');
                let tipo1 = 0;
                let tipo2 = 0;
                let tipo5 = 0;
                let tipo7 = 0;
                let tipoPie1 = 0;
                let tipoPie2 = 0;
                let tipoPie5 = 0;
                let tipoPie7 = 0;
                let canceladoValue = 0;
                let aguardandoValue = 0;
                let concluidoValue = 0;
                let pendenteValue = 0;
                if (propostasCardData.data) {
                    Object.keys(propostasCardData.data).forEach(key => {
                        const elem = propostasCardData.data[key];
                        elem.forEach(data => {
                            if (data?.idstatus == 1) {
                                tipo1 += data.quantidade;
                                if (data.dataproposta == nowDayFormat) {
                                    concluidoValue = data.quantidade;
                                }
                            }
                            if (data?.idstatus == 2) {
                                tipo2 += data.quantidade;
                                if (data.dataproposta == nowDayFormat) {
                                    aguardandoValue = data.quantidade;
                                }
                            }
                            if (data?.idstatus == 5) {
                                tipo5 += data.quantidade;
                                if (data.dataproposta == nowDayFormat) {
                                    canceladoValue = data.quantidade;
                                }
                            }
                            if (data?.idstatus == 7) {
                                tipo7 += data.quantidade;
                                if (data.dataproposta == nowDayFormat) {
                                    pendenteValue = data.quantidade;
                                }
                            }
                        });
                    });
                    arrayDataCard.push({
                        concluidoValueUnit: concluidoValue,
                        aguardandoValueUnit: aguardandoValue,
                        canceladoValueUnit: canceladoValue,
                        pendenteValueUnit: pendenteValue,
                        concluidoValue: tipo1,
                        aguardandoValue: tipo2,
                        canceladoValue: tipo5,
                        pendenteValue: tipo7,
                    });
                } else {
                    return;
                }

                if (propostasDataValor.data) {
                    Object.keys(propostasPieData.data).forEach(key => {
                        const elem = propostasPieData.data[key];
                        elem.forEach(data => {
                            if (data?.idstatus == 1) {
                                tipoPie1 += data.quantidade;
                            }
                            if (data?.idstatus == 2) {
                                tipoPie2 += data.quantidade;
                            }
                            if (data?.idstatus == 5) {
                                tipoPie5 += data.quantidade;
                            }
                            if (data?.idstatus == 7) {
                                tipoPie7 += data.quantidade;
                            }
                        });
                    });
                    arrayDataPie.push({
                        concluidoWeekValue: tipoPie1,
                        aguardandoWeekValue: tipoPie2,
                        canceladoWeekValue: tipoPie5,
                        pendenteWeekValue: tipoPie7,
                    });
                } else {
                    return;
                }

                const arrayData = propostasDataValor.data.map(propostaValor => {
                    const newPropostaValor = { ...propostaValor };
                    newPropostaValor.valorsolicitado = parseFloat(newPropostaValor.valorsolicitado);
                    if (newPropostaValor.idstatus == 1) {
                        newPropostaValor.descricaostatus = 'Concluído';
                    } else if (newPropostaValor.idstatus == 2) {
                        newPropostaValor.descricaostatus = 'Aguardando';
                    } else if (newPropostaValor.idstatus == 5) {
                        newPropostaValor.descricaostatus = 'Cancelado';
                    }
                    return newPropostaValor;
                });
                const arrayPie = arrayData.filter(
                    data =>
                        data.idstatus === 1 ||
                        data.idstatus === 2 ||
                        data.idstatus === 5 ||
                        data.idstatus === 7,
                );
                yield put({ type: 'handlerSetRetailerDataProposta', payload: clienteMes });
                yield put({ type: 'handlerSetRetailerDataClient', payload: propostasData });
                yield put({
                    type: 'handlerSetRetailerDataClientMapping',
                    payload: arrayDataSource,
                });
                yield put({ type: 'handlerSetRetailerDataPropostasCard', payload: arrayDataCard });
                yield put({ type: 'handlerSetRetailerDataPropostasPie', payload: arrayPie });
                yield put({ type: 'handlerSetRetailerDataPropostasValor', payload: arrayData });
                yield put({ type: 'setRetailerDataProposalValuesSorted', payload: arrayData });
                yield put({ type: 'spin/setSpinning', payload: false });
            } catch (error) {
                Console(error);
                yield put({
                    type: 'handlerRequestError',
                    payload: error,
                });
                yield put({ type: 'spin/setSpinning', payload: false });
            }

            yield put({
                type: 'handlerRequestLoading',
                requestLoading: false,
            });
        },
        *getProposal(_, { call, put }) {
            const {
                datainicio,
                datafinal,
                situacao,
                idcdclojista,
                idcdclojistafiliais,
            } = _.payload;

            yield put({ type: 'spin/setSpinning', payload: true });
            try {
                const getProposalReponse = yield call(getProposal, {
                    datafinal,
                    datainicio,
                    situacao,
                    idcdclojista,
                    idcdclojistafiliais,
                });

                getProposalReponse.data = getProposalReponse.data.map(proposal => ({
                    ...proposal,
                    cpf_formated: formatToCPF(proposal.cnpj_cpf),
                }));

                yield put({ type: 'handlerSetProposal', payload: getProposalReponse });
                yield put({ type: 'spin/setSpinning', payload: false });
            } catch (error) {
                Console(error);
                yield put({
                    type: 'handlerRequestError',
                    payload: error,
                });
                yield put({ type: 'spin/setSpinning', payload: false });
            }

            yield put({
                type: 'handlerRequestLoading',
                requestLoading: false,
            });
        },
        *getLimit(_, { call, put }) {
            yield put({ type: 'spin/setSpinning', payload: true });
            try {
                const [limitLojista] = yield all([call(dash_board_lojista_limite, {})]);

                yield put({ type: 'handlerSetRetailerLimitLojista', payload: limitLojista });
                yield put({ type: 'spin/setSpinning', payload: false });
            } catch (error) {
                Console(error);
                yield put({
                    type: 'handlerRequestError',
                    payload: error,
                });
                yield put({ type: 'spin/setSpinning', payload: false });
            }

            yield put({
                type: 'handlerRequestLoading',
                requestLoading: false,
            });
        },
    },
};
