import {
    urlBaseSimulacao,
    urlGetLimitValue,
    baseRestCDC_API,
    urlBaseGetPessoa,
    urlBaseUltimaAnalise,
} from '@/utils/Requests/url';
import { getToken } from '@/services/Controllers/Token';
import { axiosRequest } from './get';
import { request } from './request';

export const simulation_get = async (data, callbackSuccess, callbackError) => {
    const options = {
        method: 'post',
        url: urlBaseSimulacao,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        data: {
            ...data,
        },
    };

    await axiosRequest(options, callbackSuccess, callbackError);
};

export const simulation_getLimite = async (data, callbackSuccess, callbackError) => {
    const { idcdclojista } = data;
    const options = {
        method: 'get',
        url: urlGetLimitValue(idcdclojista),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };

    await axiosRequest(options, callbackSuccess, callbackError);
};

export const simulation_getData = async data => {
    const options = {
        method: 'post',
        url: urlBaseSimulacao,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        data: {
            ...data,
        },
    };
    return request(options);
};

export const simulation_getLimiteData = async data => {
    const { idcdclojista } = data;
    const options = {
        method: 'get',
        url: urlGetLimitValue(idcdclojista),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };

    return request(options);
};

export const simulation_prospection_client = async data => {
    const options = {
        method: 'post',
        url: `${baseRestCDC_API}/prospection/client`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        data: {
            ...data,
        },
    };
    return request(options);
};

export const simulation_fetch_siga_prospection = async data => {
    const options = {
        method: 'get',
        url: `${urlBaseGetPessoa}${data.cnpj_cpf}`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        params: {
            dadoscobradora: 1,
        },
    };
    return request(options);
};

export const simulation_dcc_ultima_analise = async data => {
    const options = {
        method: 'get',
        url: `${urlBaseUltimaAnalise}${data.idfilial}/${data.idcnpj_cpf}`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    return request(options);
};
