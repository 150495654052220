export default {
    namespace: 'spin',
    state: {
        loading: false,
    },
    reducers: {
        setSpinning(state, { payload: loading }) {
            return {
                ...state,
                loading,
            };
        },
    },
};
