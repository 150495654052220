import {
    urlBaseUser,
    urlUpdatePasswordUser,
    urlBaseProfile,
    urlListagemPerfilUsuario,
    urlTrocaLogin,
} from '@/utils/Requests/url';
import { axiosRequest } from '@/services/Request/get';
import { getToken } from '../Controllers/Token';
import { request } from './request';

export const user_get = async (callbackSuccess, callbackError) => {
    const options = {
        method: 'get',
        url: urlBaseUser,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };

    await axiosRequest(options, callbackSuccess, callbackError);
};

export const user_insert = async (data, callbackSuccess, callbackError) => {
    const options = {
        method: 'post',
        url: urlBaseUser,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        data: {
            ...data,
        },
    };

    await axiosRequest(options, callbackSuccess, callbackError);
};

export const user_update = async (data, callbackSuccess, callbackError) => {
    const { idcdcusuario } = data;
    const options = {
        method: 'put',
        url: `${urlBaseUser}/${idcdcusuario}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        data: {
            ...data,
        },
    };

    await axiosRequest(options, callbackSuccess, callbackError);
};

export const update_password = async (data, callbackSuccess, callbackError) => {
    const { idUsuario, ...rest } = data;

    const options = {
        method: 'put',
        url: `${urlUpdatePasswordUser}${idUsuario}/redefinirsenha`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        data: {
            ...rest,
        },
    };

    await axiosRequest(options, callbackSuccess, callbackError);
};

export const user_getProfile = async (callbackSuccess, callbackError) => {
    const options = {
        method: 'get',
        url: urlBaseProfile,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };

    await axiosRequest(options, callbackSuccess, callbackError);
};

export const user_getPerfil = async (callbackSuccess, callbackError) => {
    const options = {
        method: 'get',
        url: urlListagemPerfilUsuario,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    await axiosRequest(options, callbackSuccess, callbackError);
};

export const user_getData = async () => {
    const options = {
        method: 'get',
        url: urlBaseUser,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    return request(options);
};

export const user_change = async data => {
    const { idcdcusuario, ip } = data;
    const options = {
        method: 'post',
        url: urlTrocaLogin(idcdcusuario),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        data: {
            ...data,
        },
    };

    return request(options);
};
