import { LocalStorage, LocalStoragePerson } from '@/services/LocalStorage';
import { decodeTokenAPI } from '@/services/Controllers/Token';

const _ = require('lodash');

export default class LocalStorageSimulation extends LocalStorage {
    constructor() {
        super(LOCAL_STORAGE_KEYS.SIMULACAO);
    }

    static initLocalStorageSimulation = localStorageEntity => {
        const simulationData = this.getLocalStorageData() || {};
        simulationData[LocalStoragePerson.getKey()] = LocalStoragePerson.localStorageInitPerson(
            localStorageEntity,
        );
        this.setLocalStorageData(simulationData);
    };

    static getKey = () => LOCAL_STORAGE_KEYS.SIMULACAO;

    static getLocalStorageData = () => super.getLocalStorageData(LOCAL_STORAGE_KEYS.SIMULACAO);

    static getInitialStepPage = () => STEP_PAGES.SIMULACAO;

    static getInitialRoute = () => PATH.SIMULACAO;

    static setLocalStorageData = value => {
        super.setLocalStorageData(LOCAL_STORAGE_KEYS.SIMULACAO, value);
    };

    static setLocalStoragePerson = data => {
        const simulationData = this.getLocalStorageData() || {};
        const currentPersonKey = LocalStoragePerson.getKey();
        if (data) {
            simulationData[currentPersonKey] = data;
        } else {
            simulationData[currentPersonKey] = simulationData[currentPersonKey] || {};
        }
        super.setExpirationTime(simulationData[currentPersonKey]);
        super.setUserId(simulationData[currentPersonKey]);
        this.setLocalStorageData(simulationData);
    };

    static findRegistersByUser = () => {
        const simulationObject = this.getLocalStorageData();
        const { idcdcusuario } = decodeTokenAPI();
        const registersByUser = [];
        _.mapKeys(simulationObject, register => {
            if (register.siga) {
                if (
                    register.idcdcusuario == idcdcusuario &&
                    register.siga.cnpj_cpf &&
                    register.siga.idcnpj_cpf &&
                    register.siga.nome
                ) {
                    registersByUser.push(register);
                }
            }
        });
        return registersByUser;
    };
}
