import { getAuthorities } from '@/pages/Route/services';

export function getAuthority(str) {
    const authorityDecode = getAuthorities();
    const authorityString = typeof str === 'undefined' ? authorityDecode : str;

    let authority;

    try {
        if (authorityString) {
            authority = JSON.parse(authorityString);
        }
    } catch (e) {
        authority = authorityString;
    }

    if (typeof authority === 'string') {
        return [authority];
    }

    if (!authority && process.env.REACT_APP_ENV === 'site') {
        return ['1'];
    }
    return authority;
}

export function setAuthority(authority) {
    const proAuthority = typeof authority === 'string' ? [authority] : authority;
    return localStorage.setItem('antd-pro-authority', JSON.stringify(proAuthority));
}
