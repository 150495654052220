export const baseSigaREST = `${CONFIG.URL}/siga/server/`;

export const baseSiga = `${CONFIG.URL}/siga/`;

export const baseRestCDC_API = CONFIG.URL;

export const GSIGN_ROUTE = `${baseRestCDC_API}/gsign`;

export const urlBaseSimulacao = `${baseRestCDC_API}/simulacoes`;

export const urlBaseProposta = `${baseRestCDC_API}/propostas`;
export const urlPropostaStatus = `${urlBaseProposta}/situacoes`;

export const urlCancelarProposta = `${baseRestCDC_API}/lojistas/propostas/`;

export const urlTipoocupacao = `${baseSiga}pessoa/auxiliares/tipoocupacao`;

export const urlTipoSexo = `${baseSiga}pessoa/auxiliares/tiposexo`;

export const urlTipoEstadoCivil = `${baseSiga}pessoa/auxiliares/tipoestadocivil`;

export const urlNivelFormacao = `${baseSiga}pessoa/auxiliares/nivelformacao`;

export const urlTime = `${baseSiga}pessoa/auxiliares/gztimefutebol`;

export const urlCidades = `${baseSiga}cidades`;

export const urlCep = `${baseSiga}cep/`;

export const urlCNAE = `${baseSiga}pessoa/cnae`;

export const urlCBO = `${baseSiga}pessoa/cbo?`;

export const urlConjuge = cpf => `${baseSiga}pessoa/conjuge/${cpf}`;

export const urlSigaTipoUrgenciaAnalise = `${baseSiga}pessoa/auxiliares/siga_tipo_urgencia_analise`;

export const urlSigaTipoContribuinte = `${baseSiga}pessoa/auxiliares/tipocontribuinte`;

export const urlBaseAuxiliares = `${baseSiga}pessoa/auxiliares`;

export const urlSignUpRetailer = `${baseRestCDC_API}/lojistas/filiais`;
export const urlGetAllRetailer = `${baseRestCDC_API}/lojistas`;
export const urlGetAllOccupationTypeShopkeeper = `${baseRestCDC_API}/tipoocupacaolojista/lojista`;
export const urlPostAllOccupationTypeShopkeeper = `${baseRestCDC_API}/tipoocupacaolojista`;
export const urlDeleteAllOccupationTypeShopkeeper = `${baseRestCDC_API}/tipoocupacaolojista`;
export const urlBaseLimiteLojista = `${baseRestCDC_API}/lojistas/limite/filiais/`;

export const urlGetAllRetailerContracts = `${baseRestCDC_API}/lojistas/contratos`;

export const urlBaseRetailerContractAnalysis = idcdclojista =>
    `${baseRestCDC_API}/lojistas/${idcdclojista}/contratos/analise`;

export const urlUpdateRetailer = id => `${baseRestCDC_API}/lojistas/${id}/filiais`;

export const urlValidadeCNPJ_CPF = (cnpj_cpf, tipo) =>
    `${baseRestCDC_API}/lojistas/${cnpj_cpf}/tipo/${tipo}`;

export const urlGetRetailerCode = cnpj => `${baseRestCDC_API}/lojistas/${cnpj}/gazincred`;

export const urlGetAllBanks = `${baseRestCDC_API}/bancos`;

export const urlGetAllPlans = cnpj_cpf => `${baseRestCDC_API}/lojistas/${cnpj_cpf}/plano`;

export const urlGetLimitValue = idcdclojista =>
    `${baseRestCDC_API}/lojistas/${idcdclojista}/limite`;

export const urlPropostasLojista = (idLojista, idProposta) =>
    `${baseRestCDC_API}/lojistas/${idLojista}/propostas/${idProposta}`;

export const urlTipoOcupacaoDoc = `${baseSiga}pessoa/ocupacao/documentos/`;

export const urlBaseDocumentosArkivus = `${baseSiga}arkivus/documentos/`;

export const urlGetPersonDataByCpf = `${baseSiga}epc/`;

export const urlArkivusFix = 'https://www3.acesso.io/gazin/Account/Login.aspx';

export const urlArkivusSalvarSenha = `${baseSiga}arkivus/login`;

export const urlArkivusGet = `${baseSiga}arkivus/`;

export const urlBaseGetFilial = `${baseSiga}filial`;

export const urlBaseUltimaAnalise = `${baseSiga}dcc/ultimaanalise/`;
export const urlBaseAnalisarCadastroLoja = `${baseSiga}dcc/analisarcadastroloja/`;
export const urlBaseIniciarCadastroLoja = `${baseSiga}dcc/iniciarcadastroloja/`;

export const urlBaseGetPessoa = `${baseSiga}pessoa/`;
export const urlBaseUpdatePessoa = `${baseRestCDC_API}/pessoas/`;
export const urlBaseLimiteTemporario = `${baseSiga}pessoa/limite/`;
export const urlBaseListaAprovacao = `${baseRestCDC_API}/pessoa/aprovacao`;
export const urlBaseLimite = `${baseSiga}pessoa/limite/liquido/`;
export const urlBaseClienteInformado = `${baseSiga}pessoa/clienteinformado/`;

const urlBaseAnalise = `${baseSiga}analise/`;
export const urlBaseSincronizarAnalise = `${urlBaseAnalise}sincronizar/bidirecional`;

export const urlBaseValidarChaveCadastro = `${baseSiga}chave/cadastro/filial/`;

export const urlBaseBuscaDocumentosRequeridos = `${baseSiga}pessoa/ocupacao/documentos/`;

export const urlBaseLimiteLiquido = (cpf, idfilial) =>
    `${baseSiga}pessoa/limite/liquido/${cpf}/${idfilial}`;

export const urlBaseGetConsultaEntidade = `${baseRestCDC_API}/epc/`;

export const urlBaseGetAnalisarLojistaContrato = idcdclojistafilial =>
    `${baseRestCDC_API}/lojistas/${idcdclojistafilial}/contratos/assinado`;

export const urlBaseUser = `${baseRestCDC_API}/usuarios`;

export const urlBaseLojistaStatus = `${baseRestCDC_API}/contratos/status`;

export const urlBaseLojistaRejeicoes = `${baseRestCDC_API}/contratos/rejeicoes`;

export const urlBaseProfile = `${baseRestCDC_API}/perfilusuarios`;

export const urlListagemPerfilUsuario = `${baseRestCDC_API}/perfis`;

export const urlPdfProposta = `${baseRestCDC_API}/lojistas/propostas`;

export const urlPropostaContrato = (idcdcproposta, idcdclojista) =>
    `${baseRestCDC_API}/lojistas/${idcdclojista}/propostas/${idcdcproposta}/contratos`;

export const urlPropostaConfirmar = idcdcproposta =>
    `${baseRestCDC_API}/lojistas/propostas/${idcdcproposta}`;

export const urlPropostaBoleto = idcdcproposta =>
    `${baseRestCDC_API}/lojistas/propostas/${idcdcproposta}/boletos`;
export const urlBasePropostaBoleto = `${baseRestCDC_API}/lojistas/propostas/boletos`;

export const urlPropostaContratoAssinado = idcdcproposta =>
    `${baseRestCDC_API}/lojistas/propostas/${idcdcproposta}/contratos/assinado`;

export const urlPdfDoc = `${baseRestCDC_API}/lojistas/propostas/creditos`;

export const urlTrocaLogin = idcdcusuario => `${baseRestCDC_API}/login/${idcdcusuario}`;

export const urlUpdatePasswordUser = `${baseRestCDC_API}/usuarios/`;

export const getEnderecoCidadeCepService = `${baseSiga}/cep/endereco/cidade`;

export const urlCancelarAnalise = `${baseSiga}dcc/cancelaranalise/`;

export const urlConsultaLimite = idcdclojista =>
    `${baseRestCDC_API}/lojistas/${idcdclojista}/limite`;

export const urlConsultaPropostas = (firstday, lastday) =>
    `${baseRestCDC_API}/dashboard/propostas/dataproposta/inicio/${firstday}/final/${lastday}`;

export const urlContratoAprovadoReprovado = idcdclojista =>
    `${baseRestCDC_API}/lojistas/${idcdclojista}/contratos`;

export const urlUploadContrato = idcdclojista =>
    `${baseRestCDC_API}/lojistas/${idcdclojista}/contratos`;
export const urlRejeitarContrato = idcdcpropostacontrato =>
    `${baseRestCDC_API}/lojistas/propostas/contratos/${idcdcpropostacontrato}`;
// top
export const urlConsultaClientes = (firstday, lastday) =>
    `${baseRestCDC_API}/dashboard/clientes/propostas/periodos/inicio/${firstday}/final/${lastday}`;

export const urlConsultaPropostasValor = (firstday, lastday) =>
    `${baseRestCDC_API}/dashboard/propostas/total/dataproposta/inicio/${firstday}/final/${lastday}`;

export const urlConsultaLimiteDashboard = `${baseRestCDC_API}/dashboard/limite`;

export const urlContratoDownload = idcdclojistafillial =>
    `${baseRestCDC_API}/lojistas/filiais/${idcdclojistafillial}/contratos/download`;

export const urlGetTokenContrato = `${baseRestCDC_API}/lojistas/token`;

export const urlGetInitialMessage = `${baseRestCDC_API}/websocket/mensagens`;

export const urlGetFullMessage = idcdcmensagem =>
    `${baseRestCDC_API}/websocket/mensagens/${idcdcmensagem}`;

export const getPendenciasClientes = idcnpjcpf => `${baseRestCDC_API}/cliente/${idcnpjcpf}/dividas`;

export const getClientesInfo = nome => `${baseSiga}titulo/pessoa/${nome}`;

export const urlBaseProposalPerson = `${baseSiga}titulo/pessoa`;

export const urlBaseClient = `${baseRestCDC_API}/cliente`;

export const urlBaseProfiles = `${baseRestCDC_API}/perfis`;
export const urlBaseRoutes = `${baseRestCDC_API}/rotas`;
export const urlSendMessage = `${baseRestCDC_API}/websocket/mensagens`;
export const getUrlRoutes = `${baseRestCDC_API}/v1/rotas`;
export const urlBaseRoutesSameLevel = `${baseRestCDC_API}/v2/rotas`;

export const urlBaseReport = `${baseRestCDC_API}/report`;

export const urlEnvioPdf = `${baseRestCDC_API}/relatorios/gerar`;
export const urlSituationTitle = `${baseRestCDC_API}/situacoestitulos`;
export const urlBaseShopkeeper = `${baseRestCDC_API}/lojistas`;

export const checkClientSendProposal = (idcdclojista, cnpjcpf) =>
    `${baseRestCDC_API}/lojistas/${idcdclojista}/clientes/${cnpjcpf}`;

export const getIdShopkeeperByCpfHandler = cnpj_cpf =>
    `${baseRestCDC_API}/cliente/${cnpj_cpf}/idlojista`;

export const urlBaseParameterScoreByAge = `${baseRestCDC_API}/parametros/score`;
export const urlBaseParameterScoreByAgeValidate = `${baseRestCDC_API}/parametros/score/validacao`;
export const urlBaseContractCheck = '/lojistas/contrato/validacao';

export const urlGetClassificationScore = `${CONFIG.FENIX_URL}/classification-score`;

export const urlUpdateClassificationScore = `${CONFIG.FENIX_URL}/classification-score/sync`;
