export default {
    namespace: 'customer',
    state: {
        classification: null,
    },
    reducers: {
        setClassification(state, { payload: classification }) {
            return {
                ...state,
                classification,
            };
        },
    },
};
