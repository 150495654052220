import axios from 'axios';

const checkStatus = response => {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
};

export const request = async options => {
    const response = await axios({ ...options });
    checkStatus(response);
    return response;
};
