import { formatOnlyNumber } from '@/utils/formatFormInput';
import { urlCidades, urlCep, getEnderecoCidadeCepService } from '@/utils/Requests/url';
import { request } from '@/services/Request/request';
import { axiosRequest } from './get';
import { getToken } from '../Controllers/Token';

export const cidade_get = async (callbackSuccess, callbackError, nomeCidade) => {
    const options = {
        method: 'GET',
        url: `${urlCidades}?cidade=${nomeCidade}`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    await axiosRequest(options, callbackSuccess, callbackError);
};

export const cep_getData = cep => {
    const formatedCep = formatOnlyNumber(cep);
    const options = {
        method: 'get',
        url: `${urlCep}${formatedCep}`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };

    return request(options);
};

export const cep_endereco_cidade_get = async (data, callbackSuccess, callbackError) => {
    const { ...rest } = data;
    const options = {
        method: 'GET',
        url: getEnderecoCidadeCepService,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        params: {
            ...rest,
        },
    };
    await axiosRequest(options, callbackSuccess, callbackError);
};

export const endereco_get = async (callbackSuccess, callbackError, cep) => {
    const options = {
        method: 'GET',
        url: `${urlCep}endereco/cidade?cidade=${cep}`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    await axiosRequest(options, callbackSuccess, callbackError);
};
