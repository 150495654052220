import { formatToCNPJ } from 'brazilian-values';
import { idCnpjToCnpj } from '@/utils/formatFormInput';

const _ = require('lodash');

export const formatToTable = (retailerArray, contractArray, statusArray) => {
    const branches = [];
    let aux = [];
    _.map(retailerArray, (retailer, index) => {
        const cnpj = idCnpjToCnpj(retailer.idcnpjcpftitular);
        aux = retailer;
        aux.cnpj_cpf = cnpj;
        aux.cnpj_cpfFormatted = formatToCNPJ(cnpj);
        const contract = getContractByBranch(contractArray, aux.idcdclojista);
        aux.descricaoStatusRejeicao = getRejectionStatusNameById(
            statusArray,
            contract.idcdcstatusrejeicao,
        );
        aux.key = index;
        aux = { ...aux, ...contract };
        delete aux.filiais;
        branches.push(aux);
        aux = [];
    });

    return branches;
};

const getContractByBranch = (contracts, idcdclojista) => {
    const contract = _.filter(contracts, contract => contract.idcdclojista == idcdclojista);

    return contract[0] || {};
};

const getRejectionStatusNameById = (statusArray, id) => {
    const rejectionStatus = _.find(
        statusArray,
        status => status.descricao && status.idcdcstatusrejeicao == id,
    );
    return rejectionStatus && rejectionStatus.descricao ? rejectionStatus.descricao : '';
};
