export default {
    'menu.Relatório de propostas': 'Relatório de Propostas',

    'proposalReports.init.error': 'Falha ao inicializar página. Contacte o suporte.',
    'proposalReports.page.title': 'Relatório de Propostas',

    'proposalReports.form.fetch-proposals-form.field.date.label': 'Data da proposta',
    'proposalReports.form.fetch-proposals-form.field.date.message.required':
        'Data da proposta obrigatória',

    'proposalReports.form.fetch-proposals-form.field.status.label': 'Status',
    'proposalReports.form.fetch-proposals-form.field.status.message.required': 'Status obrigatório',
    'proposalReports.form.fetch-proposals-form.field.status.message.placeholder':
        'Selecione um status',

    'proposalReports.form.fetch-proposals-form.field.shopkeeper.label': 'Lojista',
    'proposalReports.form.fetch-proposals-form.field.shopkeeper.message.required':
        'Lojista obrigatório',
    'proposalReports.form.fetch-proposals-form.field.shopkeeper.message.placeholder':
        'Selecione um lojista',

    'proposalReports.form.fetch-proposals-form.field.user.label': 'Usuário',
    'proposalReports.form.fetch-proposals-form.field.user.message.required': 'Usuário obrigatória',
    'proposalReports.form.fetch-proposals-form.field.user.message.placeholder':
        'Selecione o usuário',
};
