export const PROCESSOS_OPTIONS = [
    {
        key: 'gazin',
        label: 'Gazin',
        payload: {
            processo_pedido_produto: 9940,
            processo_pedido_venda: 9762,
            processo_titulos_a_receber: 3183,
            processo_cancel_titulos_receber: 3185,
            processo_receb_baixa_automatica: 3192,
            processo_lojista_a_pagar: 3708,
            processo_lojista_a_receber: 3190,
        }
    },
    {
        key: 'macavi',
        label: 'Macavi',
        payload: {
            processo_pedido_produto: 9265,
            processo_pedido_venda: 9764,
            processo_titulos_a_receber: 3945,
            processo_cancel_titulos_receber: 3946,
            processo_receb_baixa_automatica: 3947,
            processo_lojista_a_pagar: 3708,
            processo_lojista_a_receber: 3190,
        }
    }
];
