import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import values from 'lodash/values';

export default {
    namespace: 'contrato_model',
    state: {
        currentPageButton: {
            showPreviousButtom: true,
            showNextButtom: true,
            showEndButton: false,
        },
        positionSteps: [
            {
                key: 'download',
                disable: false,
            },
            {
                key: 'upload',
                disable: false,
            },
            {
                key: 'analise',
                disable: true,
            },
            {
                key: 'situacao',
                disable: true,
            },
        ],
        statusContrato: null,
    },
    reducers: {
        setShowFooter(state, { payload: dados }) {
            return {
                ...state,
                ...dados,
            };
        },
        setDisableStep(state, { payload: dados }) {
            const merged = merge(
                keyBy(state.positionSteps, 'key'),
                keyBy(dados.positionSteps, 'key'),
            );
            const result = values(merged);
            return {
                ...state,
                ...result,
            };
        },
    },
};
