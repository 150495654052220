export default {
    namespace: 'cadastroCliente_checklist',
    state: {},
    reducers: {
        handlerOnChange(state, { payload: dados }) {
            return {
                ...state,
                ...dados,
            };
        },
    },
};
