export default {
    namespace: 'cadastroCliente_creditProtection',
    state: {
        showRegisterModal: false,
    },
    reducers: {
        setModalStatus(state, { payload: showRegisterModal }) {
            return {
                ...state,
                showRegisterModal,
            };
        },
    },
};
