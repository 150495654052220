export const formatOnlyChar = value => {
    if (!value) return value;
    return value.replace(/[^\w\sáéíóàãõâêôç]/gi, '').replace(/[0-9]/gi, '');
};

export const removePontuation = value => {
    if (!value) return value;
    return value.replace(/[^\w\sáéíóàãõâêôç0-9]/gi, '');
};

export const formatOnlyNumber = value => {
    if (!value) return value;
    return value.replace(/[^0-9]/gi, '');
};

export const formatOnlyNumberString = value => {
    if (!value) return value;
    return value.replace(/\D/g, '')
};

export const formatTextSiga = value => {
    if (!value) return value;
    return value.replace(/[^_]/gi, '');
};

const applyCPFMask = value => {
    value = value.replace(/(\d{3})(\d)/, '$1.$2')
    value = value.replace(/(\d{3})(\d)/, '$1.$2')
    value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
    return value;
}

export const formatCPFMask = value => {
    if (value != null && value != undefined) {
        value = value.replace(/\D/g, '')

        if (value.length <= 11) { // CPF
            return applyCPFMask(value);
        }

        const newValue = value.slice(0, -1);
        return applyCPFMask(newValue);
    }
};

export const formatCPFOrCNPJMask = value => {
    if (value != null && value != undefined) {
        value = value.replace(/\D/g, '')

        if (value.length <= 11) { // CPF
            return applyCPFMask(value);
        } if (value.length >= 11 && value.length <= 14) {
            return applyCNPJMask(value);
        }

        const newValue = value.slice(0, -1);
        return applyCNPJMask(newValue);
    }
};

const applyCNPJMask = value => {
    value = value.replace(/^(\d{2})(\d)/, '$1.$2')
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    value = value.replace(/\.(\d{3})(\d)/, '.$1/$2')
    value = value.replace(/(\d{4})(\d)/, '$1-$2')
    return value;
}

export const formatCNPJMask = value => {
    value = value.replace(/\D/g, '')

    if (value.length <= 14) { // CPF
        return applyCNPJMask(value);
    }

    const newValue = value.slice(0, -1);
    return applyCNPJMask(newValue);
}

const applyRGMask = value => {
    value = value.replace(/(\d{2})(\d)/, '$1.$2')
    value = value.replace(/(\d{3})(\d)/, '$1.$2')
    value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
    return value;
}

export const formatRGMask = value => {
    value = value.replace(/\D/g, '');
    if (value.length <= 9) {
        return applyRGMask(value);
    }

    const newValue = value.slice(0, -1);
    return applyRGMask(newValue);
};

export const percent = value => {
    let v = value.replace(/[^0-9]/g, '');
    v = `${(v / 100).toFixed(2)}`;
    v = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(v);
    return v.replace('R$', '');
}

export const money = value => {
    let v = value.replace(/[^0-9]/g, '');
    v = `${(v / 100).toFixed(2)}`;
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(v)
}

export const moneyFormatter = value => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)

export const percentFormatter = value => new Intl.NumberFormat('pt-BR', { style: 'percent', maximumFractionDigits: 2, }).format(value);

const applyCEPMask = value => {
    value = value.replace(/(\d{2})(\d)/, '$1.$2')
    value = value.replace(/(\d{3})(\d)/, '$1-$2')
    return value;
}

export const formatCEPMask = value => {
    value = value.replace(/\D/g, '');
    if (value.length <= 8) {
        return applyCEPMask(value);
    }

    const newValue = value.slice(0, -1);
    return applyCEPMask(newValue);
};

export const formatPhoneNumberMask = value => {
    value = value.replace(/\D/g, '')
    if (value.length == 10 && value != 0) {
        var number = value.match(/(\d{2})(\d{4})(\d{4})/); <br />
        return `(${number[1]}) ${number[2]}-${number[3]}`;
    } if (value.length == 11) {
        var number = value.match(/(\d{2})(\d{5})(\d{4})/); <br />
        return `(${number[1]}) ${number[2]}-${number[3]}`;
    } if (value.length > 11) {
        var number = value.match(/(\d{2})(\d{5})(\d{4})(\d{1})/); <br />
        return `(${number[1]}) ${number[2]}-${number[3]}`;
    }
        return value
};

export const formatRamal = value => {
    value = value.replace(/\D/g, '');
    if (value.length <= 5) {
        return value;
    }

    const newValue = value.slice(0, -1);
    return newValue;
};

export const formatNumber = value => {
    value += '';
    const list = value.split(',');
    let num = list[0];
    let result = '';
    while (num.length > 3) {
        result = `.${num.slice(-3)}${result}`;
        num = num.slice(0, num.length - 3);
    }
    if (num) {
        result = num + result;
    }
    return `${result}${list[1] ? `,${list[1]}` : ''}`;
}

export const idCPF_To_CPF = value => value.replace(/1000/g, '')

export const idCnpjToCnpj = value => value.substr(1)

export const intToDouble = value => {
    const re = /\b(\d+)(\d{2})\b/;
    const str = value.toString();
    const subst = '$1.$2';
    return str.replace(re, subst);
}

export const splitDDDFromPhone = value => {
    if (!value) return value
    value = formatOnlyNumberString(value.toString())
    if (value.length < 2 && value != null) {
        return value.toString().split(' ')
    } if (value.length >= 2 && value != null) {
        const val = `${value.toString().slice(0, 2)} ${value.toString().slice(2)}`
        return val.split(' ')
    }
}
