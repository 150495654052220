export const isObject = value => value && (typeof value === 'object' || value instanceof Object);

export const isString = value => typeof value === 'string' || value instanceof String;

export const isDefined = value => value != undefined;

export const isFunction = variableToCheck => {
    if (variableToCheck instanceof Function) {
        return true;
    }
    return false;
};
