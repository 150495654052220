import {
    urlBaseLojistaStatus,
    urlBaseLojistaRejeicoes,
    urlBaseGetAnalisarLojistaContrato,
    urlBaseRetailerContractAnalysis,
} from '@/utils/Requests/url';
import { axiosRequest } from '@/services/Request/get';
import { getToken } from '../Controllers/Token';
import { request } from './request';

export const analisarlojistaStatus_get = async (callbackSuccess, callbackError) => {
    const options = {
        method: 'get',
        url: urlBaseLojistaStatus,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };

    await axiosRequest(options, callbackSuccess, callbackError);
};

export const analisarlojistaStatusRejeicao_get = async (callbackSuccess, callbackError) => {
    const options = {
        method: 'get',
        url: urlBaseLojistaRejeicoes,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };

    await axiosRequest(options, callbackSuccess, callbackError);
};

export const statusRejeicao_get = async () => {
    const options = {
        method: 'get',
        url: urlBaseLojistaRejeicoes,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };

    return request(options);
};

export const analisarlojistaContrato_get = async (data, callbackSuccess, callbackError) => {
    const { idcdclojista } = data;
    const options = {
        method: 'get',
        url: urlBaseGetAnalisarLojistaContrato(idcdclojista),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };

    await axiosRequest(options, callbackSuccess, callbackError);
};

export const analisarlojistaStatus_getData = async data => {
    const options = {
        method: 'get',
        url: urlBaseLojistaStatus,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        data: {
            ...data,
        },
    };
    return request(options);
};

export const analisarlojistaStatusRejeicao_getData = async data => {
    const options = {
        method: 'get',
        url: urlBaseLojistaRejeicoes,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        data: {
            ...data,
        },
    };
    return Request(options);
};

export const analisarFilial_setStatus = async (data, callbackSuccess, callbackError) => {
    const { idcdclojista, ...rest } = data;
    const options = {
        method: 'put',
        url: urlBaseRetailerContractAnalysis(idcdclojista),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        data: {
            ...rest,
        },
    };

    await axiosRequest(options, callbackSuccess, callbackError);
};
