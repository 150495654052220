export default {
    namespace: 'model_routes',
    state: {
        routes: [],
    },
    reducers: {
        setRouteData(state, { payload: routes }) {
            return {
                ...state,
                routes,
            };
        },
    },
    effects: {
        *setRoutes(_, { put }) {
            yield put({ type: 'setRouteData', payload: _.payload });
        },
    },
};
