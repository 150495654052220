import { user_getData, user_change } from '@/services/Request/user';
import { errorHandler } from '@/services/Request/error';
import {
    setToken,
    setTokenChange,
} from '@/services/Controllers/Token';
import { all } from 'redux-saga/effects';
import { Console } from '@/utils/Console';

const lodash = require('lodash');

export default {
    namespace: 'model_ChangeUser',
    state: {
        allUsers: [],
    },
    reducers: {
        handlerSetInitChange(state) {
            return {
                ...state,
                allUsers: [],
            };
        },
        handlerSetAllUsers(state, { payload: dados }) {
            return {
                ...state,
                allUsers: dados,
            };
        },
        handlerSetChangeUser(state, { payload: dados }) {
            setToken(dados);
            window.location.reload();
            return {
                ...state,
            };
        },
        handlerSetOldUser(state, { payload: dados }) {
            return {
                ...state,
                oldUserToken: dados,
            };
        },
        handlerRequestError(state, { payload: error }) {
            errorHandler(error);
            return {
                ...state,
            };
        },
    },
    effects: {
        *getChangeUsersData(_, { call, put, select }) {
            yield put({ type: 'spin/setSpinning', payload: true });
            try {
                const [allUsers] = yield all([call(user_getData, {})]);
                const changeUsers = allUsers.data.filter(user => {
                    const gazin = lodash.some(user.perfil, { idcdcperfil: 1 });
                    return gazin ? undefined : user;
                });
                yield put({ type: 'handlerSetAllUsers', payload: changeUsers });
                yield put({ type: 'spin/setSpinning', payload: false });
            } catch (error) {
                Console(error);
                yield put({
                    type: 'handlerRequestError',
                    payload: error,
                });
                yield put({ type: 'spin/setSpinning', payload: false });
            }

            yield put({
                type: 'handlerRequestLoading',
                requestLoading: false,
            });
        },
        *setChangeUserConnect(_, { call, put }) {
            yield put({ type: 'spin/setSpinning', payload: true });
            try {
                const { idcdcusuario, token } = _.payload;
                const [connectionUser] = yield all([
                    call(user_change, {
                        idcdcusuario,
                    }),
                ]);
                setTokenChange(token);
                const changeToken = connectionUser.data.token;
                yield put({ type: 'handlerSetChangeUser', payload: changeToken });
                yield put({ type: 'spin/setSpinning', payload: false });
            } catch (error) {
                Console(error);
                yield put({
                    type: 'handlerRequestError',
                    payload: error,
                });
                yield put({ type: 'spin/setSpinning', payload: false });
            }

            yield put({
                type: 'handlerRequestLoading',
                requestLoading: false,
            });
        },
    },
};
