import ArkivusRequest from '@/services/Request/ArkivusRequest';
import { Console } from '@/utils/Console';
import { Notification } from '@/components/Modal/Notification';

export default {
    namespace: 'arkivus',
    state: {
        photoURL: null,
    },
    reducers: {
        setPhotoUrl(state, { payload: photoURL }) {
            return {
                ...state,
                photoURL,
            };
        },
    },
    effects: {
        *fetchPhoto(_, { call, put }) {
            yield put({ type: 'spin/setSpinning', payload: true });
            try {
                const cpf = _.payload;
                const fetchPhotoResponse = yield call(ArkivusRequest.fetchPhoto, cpf);
                const { linkFoto } = fetchPhotoResponse.data;
                yield put({ type: 'setPhotoUrl', payload: linkFoto });
            } catch (error) {
                Console(error);
                Notification(
                    PROJECT_MESSAGE.WARNING_TYPE_MESSAGE,
                    PROJECT_MESSAGE.ERROR_TITLE,
                    PROJECT_MESSAGE.ERROR_MSG_ARKIVUS,
                );
            }
            yield put({ type: 'spin/setSpinning', payload: false });
        },
    },
};
