class EnvironmentHelper {
    isHomolog = () => process.env.REACT_APP_ENV === 'homolog';

    isProduction = () => process.env.REACT_APP_ENV === 'production';

    isDevelopment = () =>
        !EnvironmentHelper.isProduction() && !EnvironmentHelper.isHomolog();
}

module.exports = EnvironmentHelper;
